<template>
  <div>
    <vxe-table :data="tableData">
      <vxe-table-column
        title="出差确认开始时间"
        field="startTime"
      ></vxe-table-column>
      <vxe-table-column
        title="出差确认结束时间"
        field="endTime"
      ></vxe-table-column>
      <vxe-table-column title="审批状态">
        <template v-slot="{ row }">
          <span v-if="row.bpmStatus == 1">审批中</span>
          <span v-if="row.bpmStatus == 3">审批通过</span>
          <span v-if="row.bpmStatus == 4">审批驳回</span>
          <span v-if="row.bpmStatus == 5">流程追回</span>
        </template>
      </vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>
export default {
  props: {
    tableList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    tableList(newVal, oldVal) {
      console.log('数据发生改变', newVal);
      this.tableData = newVal;
    },
  },
  data() {
    return {
      tableData: [],
    };
  },
};
</script>
