var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            "custom-class": "form_drawer",
            title: _vm.drawerTitle,
            size: "80%",
            visible: _vm.formShow,
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.formShow = $event
            },
            closeDrawer: _vm.closeDrawer,
          },
        },
        [
          _c(
            "DrawerComponent",
            {
              ref: "drawer",
              attrs: {
                button: _vm.drawerButton,
                loading: _vm.loading,
                drawerConfig: _vm.drawerConfig,
              },
              on: {
                colse: function ($event) {
                  _vm.formShow = false
                },
                save: _vm.requestSave,
              },
            },
            [
              _vm.row && _vm.row.ext37 === "Y" && _vm.row.authorizationCode
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          height: "20px",
                          "line-height": "20px",
                          "font-weight": "600",
                          padding: "20px 0",
                        },
                      },
                      [_vm._v("授权委托书")]
                    ),
                    _c("div", { staticStyle: { margin: "50px 50px 0 50px" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "right",
                            "font-size": "18px",
                            "margin-right": "50px",
                          },
                        },
                        [
                          _vm._v("(编号："),
                          _c("span", { staticStyle: { "font-size": "18px" } }, [
                            _vm._v(_vm._s(_vm.row.authorizationCode)),
                          ]),
                          _vm._v(")"),
                        ]
                      ),
                      !(
                        _vm.row.authorizationCode.split("-")[1].length === 3 &&
                        _vm.row.authorizationCode.split("-")[1] <= "620"
                      )
                        ? _c("div", [
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "font-size": "16px",
                                  "margin-bottom": "40px",
                                },
                              },
                              [
                                _vm._v("兹委托"),
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "16px" } },
                                  [_vm._v(" " + _vm._s(_vm.row.cusName) + " ")]
                                ),
                                _vm._v(
                                  " 销售西凤酒系列产品，产品明细及其销售区域以经销商合同为准。"
                                ),
                              ]
                            ),
                          ])
                        : _c("div", [
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "font-size": "16px",
                                  "margin-bottom": "40px",
                                },
                              },
                              [
                                _vm._v("兹委托"),
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "16px" } },
                                  [_vm._v(" " + _vm._s(_vm.row.cusName) + " ")]
                                ),
                                _vm._v(
                                  " 销售西凤酒系列产品，销售区域以经销商合同为准。"
                                ),
                              ]
                            ),
                          ]),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "margin-left": "60px",
                            "font-size": "16px",
                            "margin-bottom": "40px",
                          },
                        },
                        [
                          _vm._v("有效期为"),
                          _c("span", { staticStyle: { "font-size": "16px" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.row.authorizationStartTime) + " "
                            ),
                          ]),
                          _vm._v("至"),
                          _c("span", { staticStyle: { "font-size": "16px" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.row.authorizationEndTime) + " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "margin-left": "40px",
                            "font-size": "16px",
                          },
                        },
                        [_vm._v("特此委托")]
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "text-align": "right",
                            "font-size": "16px",
                            "margin-right": "50px",
                          },
                        },
                        [
                          _c("span", { staticStyle: { "font-size": "16px" } }, [
                            _vm._v(_vm._s(_vm.row.ext16)),
                          ]),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "text-align": "right",
                            "font-size": "16px",
                            "margin-right": "50px",
                          },
                        },
                        [
                          _c("span", { staticStyle: { "font-size": "16px" } }, [
                            _vm._v(_vm._s(_vm.row.authorizationTime)),
                          ]),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            [
              _c(
                "el-drawer",
                {
                  attrs: {
                    "custom-class": "form_drawer",
                    title: "添加商品",
                    size: "80%",
                    visible: _vm.drawerShowProduct,
                    modal: false,
                    "append-to-body": true,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.drawerShowProduct = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "drawer-table" },
                    [
                      _vm.drawerShowProduct
                        ? _c("TableComponent", {
                            attrs: {
                              pagination: _vm.tablePaginationProduct,
                              templateData: _vm.tableDataProduct,
                              "table-loading": _vm.tableLoadingProduct,
                              searchConfig: _vm.searchConfigProduct,
                              "table-config": _vm.tableConfigProduct,
                            },
                            on: { searchData: _vm.searchProduct },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-drawer",
                {
                  attrs: {
                    "custom-class": "form_drawer",
                    title: "选择客户",
                    size: "80%",
                    visible: _vm.drawerShowCustomer,
                    modal: false,
                    "append-to-body": true,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.drawerShowCustomer = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "drawer-table" },
                    [
                      _c("TableComponent", {
                        attrs: {
                          pagination: _vm.tablePaginationCustomer,
                          templateData: _vm.tableDataCustomer,
                          "table-loading": _vm.tableLoadingCustomer,
                          searchConfig: _vm.searchConfigCustomer,
                          "table-config": _vm.tableConfigCustomer,
                        },
                        on: { searchData: _vm.searchCustomer },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-drawer",
                {
                  attrs: {
                    "custom-class": "form_drawer",
                    title: "合同列表",
                    size: "80%",
                    visible: _vm.drawerShowContract,
                    modal: false,
                    "append-to-body": true,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.drawerShowContract = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "drawer-table" },
                    [
                      _c("TableComponent", {
                        attrs: {
                          pagination: _vm.tablePaginationContract,
                          templateData: _vm.tableDataContract,
                          "table-loading": _vm.tableLoadingContract,
                          searchConfig: _vm.searchConfigContract,
                          "table-config": _vm.tableConfigContract,
                        },
                        on: { searchData: _vm.searchContract },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DrawerComponent", {
        ref: "drawer",
        attrs: {
          button: _vm.drawerButton,
          loading: _vm.loading,
          drawerConfig: _vm.drawerConfig,
        },
        on: {
          colse: function ($event) {
            _vm.formShow = false
          },
          save: _vm.requestSave,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }