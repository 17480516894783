var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "abstract" }, [
    _c("div", { staticClass: "title" }, [_vm._v("摘要")]),
    _c("div", { staticClass: "list describe" }, [
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "label" }, [_vm._v("流程名称")]),
        _c("div", { staticClass: "val" }, [
          _vm._v(_vm._s(_vm.detail.processName)),
        ]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "label" }, [_vm._v("流程编号")]),
        _c("div", { staticClass: "val" }, [
          _vm._v(_vm._s(_vm.detail.processNo)),
        ]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "label" }, [_vm._v("发起人")]),
        _c("div", { staticClass: "val" }, [
          _vm._v(_vm._s(_vm.detail.applierName)),
        ]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "label" }, [_vm._v("发起人职位")]),
        _c("div", { staticClass: "val" }, [
          _vm._v(_vm._s(_vm.detail.applierPositionName)),
        ]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "label" }, [_vm._v("主题")]),
        _c("div", { staticClass: "val" }, [_vm._v(_vm._s(_vm.detail.title))]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "label" }, [_vm._v("发起时间")]),
        _c("div", { staticClass: "val" }, [
          _vm._v(_vm._s(_vm.detail.createTime)),
        ]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "label" }, [_vm._v("详情")]),
        _c("div", { staticClass: "val" }, [_vm._v(_vm._s(_vm.detail.remarks))]),
      ]),
    ]),
    _vm.detail.taActTargetRespVos.length > 0
      ? _c("div", { staticClass: "title" }, [_vm._v("业务关键指标")])
      : _vm._e(),
    _vm.detail.taActTargetRespVos.length > 0
      ? _c(
          "div",
          { staticClass: "list" },
          [
            _c(
              "vxe-table",
              {
                attrs: { data: _vm.detail.keyTargetList, "show-overflow": "" },
              },
              _vm._l(_vm.detail.taActTargetRespVos, function (item, k) {
                return _c(
                  "vxe-table-column",
                  _vm._b({ key: k }, "vxe-table-column", item, false)
                )
              }),
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "title" }, [_vm._v("附件")]),
    _c(
      "div",
      { staticClass: "list" },
      [
        _c(
          "vxe-table",
          { attrs: { data: _vm.detail.processFiles } },
          [
            _c("vxe-table-column", {
              attrs: { field: "objectName", title: "附件名称" },
            }),
            _c("vxe-table-column", {
              attrs: { field: "createName", title: "上传者" },
            }),
            _c("vxe-table-column", {
              attrs: { field: "createDate", title: "上传时间" },
            }),
            _c("vxe-table-column", {
              attrs: { title: "操作", width: "100", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/upload/downloadController/download?objectName=" +
                              row.fileAddress,
                            download: row.objectName,
                          },
                        },
                        [_vm._v("下载")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }