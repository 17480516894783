<template>
  <div>
    <!-- 弹窗form -->
    <el-drawer
      custom-class="form_drawer"
      :title="drawerTitle"
      size="80%"
      :visible.sync="formShow"
      @closeDrawer="closeDrawer"
      :modal-append-to-body="false"
    >
      <!-- 表单 -->
      <DrawerComponent
        @colse="formShow = false"
        :loading="loading"
        :drawerConfig="drawerConfig"
        :button="drawerButton"
        @save="requestSave"
      ></DrawerComponent>
      <!-- 客户列表 -->
      <div>
        <el-drawer
          custom-class="form_drawer"
          title="选择客户"
          size="80%"
          :visible.sync="drawerShowCustomer"
          :modal="false"
          :append-to-body="true"
        >
          <div class="drawer-table">
            <TableComponent
              @searchData="searchCustomer"
              :pagination="tablePaginationCustomer"
              :templateData="tableDataCustomer"
              :table-loading="tableLoadingCustomer"
              :searchConfig="searchConfigCustomer"
              :table-config="tableConfigCustomer"
            ></TableComponent>
          </div>
          <div class="drawer-button">
            <buttonComponent
              @clickEvent="beforeSaveSelect"
              :btnType="'primary'"
              :icon="'el-icon-check'"
              >保存
            </buttonComponent>
            <buttonComponent
              @clickEvent="drawerShowCustomer = false"
              :btnType="'danger'"
              :icon="'el-icon-close'"
              >关闭
            </buttonComponent>
          </div>
        </el-drawer>
      </div>
    </el-drawer>
    <DrawerComponent
      v-if="dmsId"
      @colse="formShow = false"
      :loading="loading"
      :drawerConfig="drawerConfig"
      :button="drawerButton"
      @save="requestSave"
    ></DrawerComponent>
  </div>
</template>

<script>
import buttonComponent from '../../../../compoment/button-component/button-component';
import TableComponent from '../../../../compoment/table-component/table-component.vue';
import DrawerComponent from '../../../../compoment/drawer-component/drawer-component';

export default {
  name: 'form-component',
  props: ['dmsId', 'dmsCode'],
  components: {
    TableComponent,
    DrawerComponent,
    buttonComponent,
  },
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      selectTableData: [], // table列表选中的数据
      loading: false,
      code: '',
      id: '',
      // ---------------------- 弹窗 - 合同新增表单配置
      formShow: false,
      drawerTitle: '新增',
      drawerButton: {
        show: true,
        options: [
          {
            text: '暂存',
            fn: () => {
              this.requestSave(undefined, 'Y');
            },
          },
        ],
      },
      drawerConfig: [
        // 弹窗组件配置
        {
          title: '基本信息',
          type: 'form',
          ruleForm: {},
          formConfig: {
            // 弹窗表单配置
            disabled: false,
            items: [
              [
                {
                  label: '单据编号',
                  key: 'billCode',
                  show: false,
                },
                {
                  valueType: 'select',
                  label: '审批状态',
                  key: 'approveStatus',
                  path: 'code?dictTypeCode=dms_approval_state',
                  show: false,
                },
                {
                  label: '经销商',
                  key: 'customerName',
                  valueType: 'iconInput',
                  icon: 'el-icon-circle-plus-outline',
                  fn: () => {
                    this.searchCustomer({});
                  },
                },
                {
                  valueType: 'select',
                  label: '公司主体',
                  key: 'companyCode',
                  path: 'companyPageList',
                  options: {
                    label: 'companyName',
                    value: 'companyCode',
                  },
                  fn: (value, formData, ref) => {
                    this.$nextTick(() => {
                      this.setFormData(ref[0].selectedLabel, 'companyName');
                    });
                    this.searchBank(value);
                  },
                },
                {
                  label: '汇款人',
                  key: 'remitterName',
                },
                {
                  valueType: 'select',
                  label: '汇款类型',
                  key: 'remitType',
                  path: 'code?dictTypeCode=customer_remit_tpye',
                },
                {
                  label: '汇入金额',
                  key: 'remitAmount',
                  inputType: 'number',
                },
                {
                  label: '收款开户行',
                  key: 'collectAccount',
                  valueType: 'select',
                  options: {
                    value: 'subBank',
                    label: 'subBank',
                  },
                  fn: (value, formData, ref, data) => {
                    this.$nextTick(() => {
                      data.forEach((i) => {
                        if (i.subBank === value) {
                          this.setFormData(i.bankCode, 'collectBankName');
                          this.setFormData(i.bankName, 'remitBankName');
                        }
                      });
                    });
                  },
                },
                {
                  label: '汇入银行',
                  key: 'remitBankName',
                  disabled: true,
                },
                {
                  label: '收款账户',
                  key: 'collectBankName',
                  disabled: true,
                },
                {
                  label: '资金用途',
                  key: 'capitalPurpose',
                },
                {
                  label: '负责人',
                  key: 'responseCode',
                  valueType: 'select',
                  options: {
                    value: 'userName',
                    label: 'fullName',
                  },
                  fn: (val, formData, ref, selectData) => {
                    this.setPeople(val, selectData);
                  },
                },
                {
                  label: '所属组织',
                  disabled: true,
                  key: 'responseOrgName',
                },
                {
                  label: '银行流水号',
                  key: 'settlementNumber',
                },
                {
                  label: 'ERP凭证',
                  show: false,
                  key: 'erpBillCode',
                },
                {
                  label: '汇入凭证',
                  key: 'remitProve',
                  itemWidth: '100%',
                  valueType: 'inputUpload',
                  uploadConfig: {
                    accept: 'pdf,docx,xlsx,ppt,png,jpg',
                  },
                },
                {
                  label: '下账证明',
                  key: 'outAccountProve',
                  itemWidth: '100%',
                  valueType: 'inputUpload',
                  uploadConfig: {
                    accept: 'pdf,docx,xlsx,ppt,png,jpg',
                  },
                },
                {
                  label: '备注',
                  key: 'remarks',
                  valueType: 'textarea',
                  itemWidth: '100%',
                },
              ],
            ],
          },
          rules: {
            customerName: [
              {
                required: true,
                message: '请选择经销商',
                trigger: 'change',
              },
            ],
            companyCode: [
              {
                required: true,
                message: '请选择公司主体',
                trigger: 'change',
              },
            ],
            remitterName: [
              {
                required: true,
                message: '请输入汇款人',
                trigger: 'blur',
              },
            ],
            remitType: [
              {
                required: true,
                message: '请选择汇款类型',
                trigger: 'change',
              },
            ],
            remitAmount: [
              {
                required: true,
                message: '请输入汇入金额',
                trigger: 'blur',
              },
            ],
            collectAccount: [
              {
                required: true,
                message: '请选择收款账户',
                trigger: 'change',
              },
            ],
            responseCode: [
              {
                required: true,
                message: '请选择负责人',
                trigger: 'change',
              },
            ],
          },
        },
      ],

      // ----------------------- 弹窗 - 经销商列表配置
      drawerShowCustomer: false,
      tableLoadingCustomer: false,
      tableDataCustomer: [],
      tableConfigCustomer: {
        checkbox: true,
        checkboxFn: this.selectData,
        rows: [
          {
            label: '客户编码',
            prop: 'customerCode',
          },
          {
            label: '客户名称',
            prop: 'customerName',
          },
          {
            label: '客户类型',
            prop: 'customerTypeName',
          },
        ],
      },
      searchConfigCustomer: {
        options: [
          {
            valueName: 'customerCode',
            placeholder: '请输入客户编码',
          },
          {
            valueName: 'customerName',
            placeholder: '请输入客户名称',
          },
          {
            placeholder: '客户类型',
            valueName: 'customerType',
            valueType: 'select',
            path: 'code?dictTypeCode=cust_type',
          },
        ],
      },
      tablePaginationCustomer: {},
    };
  },

  created() {
    this.initPageState();

    /**
     * 以下两行未了处理
     * bpm待办事项引入组件查询详情 （审批流）
     */
    this.code = this.dmsCode || this.code;
    this.id = this.dmsId || this.id;
  },
  watch: {
    id(val) {
      if (val) {
        this.searchDetail();
      }
    },
  },
  methods: {
    initPageState() {
      const itemFormNames = ['approveStatus', 'billCode', 'erpBillCode'];
      if (this.code === 'view') {
        // 设置查看显示的字段
        this.setFormItemValue(itemFormNames, 'show', true);
      } else {
        this.setFormItemValue(itemFormNames, 'show', false);
      }

      if (this.code !== 'add') {
        // 非新增查询详情
        this.searchDetail();
      }
    },

    /**
     * 设置负责人相关信息
     * @param val {<void>} 选择的数据
     * @param selectData {Object} 下拉框所有数据
     */
    setPeople(val, selectData) {
      selectData.forEach((i) => {
        if (i.userName === val) {
          this.setFormData(i.fullName, 'responseName');
          this.setFormData(i.positionCode, 'responsePosCode');
          this.setFormData(i.orgCode, 'responseOrgCode');
          this.setFormData(i.orgName, 'responseOrgName');
        }
      });
    },

    /**
     * 查询负责人
     * @param customerCode {string}
     * @returns {Promise<void>}
     * */
    async searchDocking(customerCode) {
      let { result } = await this.$http.post(
        '/mdm/mdmCustomerMsgExtController/findDockingListByCustomerCode',
        { customerCode },
        {
          headers: {
            ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
          },
        },
      );
      result = result.map((v) => {
        const obj = v;
        obj.fullName = `${obj.fullName}-${obj.orgName}`;
        obj.userName = `${obj.userName}-${obj.orgCode}`;
        return obj;
      });
      this.setFormItemValue(['responseCode'], 'data', result);
    },
    /**
     * 查询业务员
     * @param userName {string}
     * @returns {Promise<void>}
     * */
    async searchCustomerCode(userName) {
      let { result } = await this.$http.get('/mdm/mdmUserController/detail', {
        userName,
      });
      result = {
        ...result,
        fullName: `${result.positionList[0].fullName}-${result.positionList[0].orgName}`,
        userName: `${result.positionList[0].userName}-${result.positionList[0].orgCode}`,
      };
      this.setFormItemValue(['responseCode'], 'data', [result]);
    },
    /**
     * 查询客户（经销商）
     * @param requestData {Object}
     * @returns {Promise<void>}
     */
    async searchCustomer(requestData) {
      this.tableLoadingCustomer = true;
      const params = { pageSize: 10, ...requestData };
      const {
        result: { data, count },
      } = await this.$http.post(
        '/mdm/mdmCustomerMsgExtController/pageList',
        params,
      );
      this.tableDataCustomer = data;
      this.tablePaginationCustomer.total = count;
      this.tableLoadingCustomer = false;
      this.showDrawer('drawerShowCustomer');
    },

    /**
     * 设置禁用
     * @param state {boolean}
     */
    setDisabled(state) {
      this.drawerConfig[0].formConfig.disabled = state;
      this.drawerButton.show = !state;
    },

    /**
     * 查询详情
     */
    async searchDetail() {
      if (this.id) {
        const { result } = await this.$http.post(
          '/dms/customerRemit/getDetail',
          { id: this.id },
          {
            headers: {
              ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
            },
          },
        );
        if (result.responseCode) {
          await this.searchCustomerCode(result.responseCode);
        }
        result.responseName = `${result.responseName}-${result.responseOrgName}`;
        result.responseCode = `${result.responseCode}-${result.responseOrgCode}`;
        this.drawerConfig[0].ruleForm = { ...result };
        await this.searchBank(result.companyCode);
        // await this.searchDocking(result.customerCode);
        this.setDisabled(this.code === 'view');
      }
    },

    /**
     * 查询汇入银行
     * @param companyCode {string} 公司主体名称
     * @returns {Promise<void>}
     * */
    async searchBank(companyCode) {
      const { result } = await this.$http.post('/mdm/bankInfo/bankPageList', {
        companyCode,
      });
      // this.setFormItemValue(['remitBankName'], 'data', result.data);
      this.setFormItemValue(['collectAccount'], 'data', result.data);
    },

    /**
     * 选择列表数据
     * @param data {Array}
     * @returns {boolean}
     */
    selectData(data) {
      this.selectTableData = data;
    },

    /**
     * 关闭弹窗
     * @param name {string}
     */
    closeDrawer(name) {
      this[name] = false;
    },

    /**
     * 显示列表弹窗 清楚选中的数据
     * @param name {string}
     */
    showDrawer(name) {
      this[name] = true;
      this.selectTableData = [];
    },

    /**
     * 显示表单部分字段
     * @param names {Array<string>}
     * @param state {boolean}
     */
    setFormItemValue(names, keyName, data) {
      this.drawerConfig.forEach((k) => {
        if (k.type === 'form') {
          k.formConfig.items[0].map((i) => {
            if (names.includes(i.key)) {
              if (i[keyName]) {
                i[keyName] = data;
              } else {
                this.$set(i, keyName, data);
              }
              return i;
            }
            return i;
          });
        }
      });
    },

    /**
     * 区分弹窗列表 - 处理对应的数据
     */
    beforeSaveSelect() {
      if (this.drawerShowCustomer === true && !this.selectMessage()) {
        this.saveSelectCustomer();
        this.closeDrawer('drawerShowCustomer');
      }
    },

    /**
     * 弹窗保存验证提示
     */
    selectMessage() {
      if (this.selectTableData.length > 1) {
        this.$message.error('只能选择一条数据');
        return true;
      }
      return false;
    },

    /**
     * 保存经销商
     */
    saveSelectCustomer() {
      this.setFormData(this.selectTableData[0].customerCode, 'customerCode');
      this.setFormData(this.selectTableData[0].customerName, 'customerName');
      // this.setFormData(this.selectTableData[0].customerName, 'remitterName');
      // this.searchDocking(this.selectTableData[0].customerCode);
      if (this.selectTableData[0].responseCode) {
        this.searchCustomerCode(this.selectTableData[0].responseCode);
      } else if (this.selectTableData[0].customerCode) {
        this.searchDocking(this.selectTableData[0].customerCode);
      }
    },

    /**
     * 提交表单数据
     * @param data {Object}
     */
    async requestSave(data = this.drawerConfig[0].ruleForm, temporaryFlag) {
      this.loading = true;
      data.responseCode = data.responseCode.split('-')[0] || data.responseCode;
      data.responseName = data.responseName.split('-')[0] || data.responseName;
      console.log(data, 888);
      data.temporaryFlag = temporaryFlag || 'N';
      const url = this.code === 'edit'
        ? '/dms/customerRemit/updateCustomerRemit'
        : '/dms/customerRemit/saveCustomerRemit';
      try {
        await this.$http.post(url, data);
        this.$message.success('保存成功');
        this.loading = false;
        this.formShow = false;
        this.getParentTable();
      } catch (e) {
        this.loading = false;
      }
    },

    /**
     * 设置表单数据
     * @param data {}
     * @param dataName {string}
     */
    setFormData(data, dataName) {
      this.$set(this.drawerConfig[0].ruleForm, dataName, data);
    },

    /**
     * 设置弹窗组件的配置
     * @param componentTitle {String}
     * @param valueName {string}
     * @param data {<void>}
     */
    setDrawerValue(componentTitle, valueName, data) {
      this.drawerConfig.forEach((item) => {
        if (item.title === componentTitle) {
          item[valueName] = data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.drawer-button {
  padding: 20px 20px;
  text-align: right;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #ebeef5;
}

.drawer-table {
  padding: 20px;
  margin-bottom: 100px;
}

.slot-body {
  display: flex;

  div {
    display: flex;
    align-items: center;
    flex-grow: 2;

    span {
      flex-shrink: 0;
      margin-right: 5px;
    }

    input {
      width: 150px;
    }
  }
}
</style>
