<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

import CancelRespVos from './components/table.vue';

formCreate.component('CancelRespVos', CancelRespVos);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'business _leave',
      formParentCode: 'CRM20210106000000436',
      rule: [],
      buttons: {
        submit: false,
      },
    };
  },

  methods: {
    // setRule(v) {
    //   const item = v;
    //   if (item.field === 'cancelRespVos') { // 选择客户
    //     item.value = [];
    //   }
    //   return item;
    // },
    formComplete() {
      // 获取详情
      console.log('formConfig', this.formConfig);
      if (this.formConfig.row.id) {
        const requestUrl = '/sfa/moblie/SfaTravel/queryTravelCancel';
        request.post(requestUrl, this.formConfig.row.id).then((res) => {
          if (res.success) {
            const cancelRespVos = this.getRule('cancelRespVos');
            const tempData = [{
              startTime: res.result.startTime,
              endTime: res.result.endTime,
              bpmStatus: res.result.bpmStatus,
            },
            ];
            cancelRespVos.props.tableList = tempData;
            this.setValue(res.result.sfaTravelRespVo);
          }
        });
      }
    },
  },
};
</script>
