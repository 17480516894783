<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'customer_contact_person',
      formParentCode: 'CRM20211218000000002',
    };
  },

  methods: {
    formComplete() {
      console.log('3333', this.formConfig, this.formConfig.row);
      if (this.formConfig.row && this.formConfig.row.id) {
        request.get('/mdm/customerMatchmakerController/queryDetail', {
          id: this.formConfig.row.id,
        }).then((res) => {
          if (res.success) {
            const { result } = res;
            this.setValue(result);
          }
        });
      }
    },
  },
};
</script>
