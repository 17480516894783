<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import BottonForm from './botton.vue';

formCreate.component('BottonForm', BottonForm);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'contract_closure_form',
      formParentCode: 'CRM20210914000002701',
      rule: [],
      buttons: {
        submit: false,
      },
    };
  },
  async created() {
    this.getDetail();
  },

  methods: {
    // 获取详情
    getDetail() {
      request
        .post('/dms/dmsclosecontract/query', {
          id: this.formConfig.row.id || this.id,
        })
        .then((res) => {
          if (res.success) {
            this.setValue({
              ...res.result,
              botton: res.result,
            });
          }
        });
    },
  },
};
</script>
