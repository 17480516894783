<template>

  <div class="custom-table">
    <vxe-table border show-overflow ref="xTable" :data="tableData">
      <vxe-table-column field="productCode" align="center" title="产品编码"></vxe-table-column>
      <vxe-table-column field="productName" align="center" title="产品名称"></vxe-table-column>
      <vxe-table-column field="productNum" align="center" title="产品数量"></vxe-table-column>
      <vxe-table-column field="productPrice" align="center" title="产品单价"></vxe-table-column>
      <vxe-table-column field="productTotalAmount" align="center" title="产品总价"></vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>

export default {
  props: {
    value: Array,
  },

  data() {
    return {
      tableData: [],
    };
  },
  watch: {
    value(newVal, oldVal) {
      this.tableData = newVal || [];
    },
  },
};
</script>
