<template>
  <div style="height: 100%">
    <p class="table-title">历史任务</p>
    <div class="table">
      <vxe-table
        border
        ref="xTable"
        show-header-overflow
        show-overflow
        highlight-hover-row
        :data="taskList"
        @radio-change="changeBox"
      >
        <vxe-table-column type="radio" width="40" fixed="left"></vxe-table-column>
        <vxe-table-column
          style="height: 36px"
          v-for="(col, index) in columns"
          :key="index"
          :field="col.field"
          :title="col.title"
          :min-width="col.width ? col.width : 80"
          :fixed="col.fixed"
          header-class-name="header-col"
        ></vxe-table-column>
      </vxe-table>
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <el-form-item label="回退意见" prop="remark">
        <el-input v-model="ruleForm.remark" type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit" :loading="$store.state.buttonApiIsLoading">确定</el-button>
      <el-button type="danger" @click="close">取消</el-button>
    </div>
  </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  name: 'BackModal',
  props: {
    formConfig: Object,
  },
  data() {
    return {
      columns: [
        {
          field: 'taskDefKey',
          title: '任务ID',
        },
        {
          field: 'taskName',
          title: '任务名',
        },
      ],
      taskList: [],
      ruleForm: {
        remark: '',
      },
      rules: {
        remark: [
          { required: true, message: '请填写回退意见', trigger: 'blur' },
        ],
      },
      currentRow: [],
    };
  },
  methods: {

    init() {
      request
        .post('/activiti/activitiRebackController/getRejectBackTask', this.formConfig)
        .then((res) => {
          const { result } = res;
          if (result) this.taskList = result;
        });
    },

    changeBox({ records, row }) {
      this.currentRow = row;
    },

    // 提交
    submit() {
      if (this.currentRow.length === 0) {
        this.$message.error('请选择回退任务');
      } else {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            const params = {
              ...this.formConfig,
              taskDefKey: this.currentRow.taskDefKey,
              remark: this.ruleForm.remark,
            };
            request
              .post('/activiti/activitiRebackController/rejectToHisTask', params)
              .then((res) => {
                this.$message.success('回退成功');
                this.close();
                this.$emit('confirmBack');
              });
          } else {
            this.$message.error('请填写回退意见');
          }
        });
      }
    },
    // 关闭
    close() {
      this.$emit('onClose');
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang='less' scoped>
.table-title {
  font-size: 14px;
  color: #606266;
}
.table {
  height: calc(100% - 82px - 21px - 14px);
  overflow-y: auto;
  border-bottom: 1px solid #e8eaec;
}
</style>
