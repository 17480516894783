<template>
  <div class="treeTable">
    <slot name="header"></slot>
    <div v-loading="loading" class="table">
      <vxe-table
        border
        ref="xTable"
        height="390px"
        show-header-overflow
        show-overflow
        highlight-hover-row
        :data="data"
        @checkbox-change="changeBox"
        @checkbox-all="changeAll"
      >
        <vxe-table-column type="checkbox" width="40" fixed="left"></vxe-table-column>
        <vxe-table-column
          style="height: 36px"
          v-for="(col, index) in columns"
          :key="index"
          :field="col.field"
          :title="col.title"
          :min-width="col.width ? col.width : 80"
          :fixed="col.fixed"
          header-class-name="header-col"
        >
        </vxe-table-column>
      </vxe-table>
    </div>
    <div class="page" v-if="total > 0">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="pageConfig.pageNum"
        :page-size="pageConfig.pageSize"
        layout="prev, pager, next, jumper"
        :pager-count="5"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- <div class="pagi">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 20, 50, 100, 150, 200]"
        :page-size="pageSize"
        small
        align="right"
        :total="total || data.length"
      />
    </div> -->
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      currentPage: 1,
      pageSize: 15,
    };
  },
  props: {
    columns: Array,
    data: Array,
    total: Number,
    pageConfig: Object,
    getList: Function,
    getCheckedList: Function,
    loading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  methods: {
    changeBox({ records }) {
      this.getCheckedList(records);
    },
    changeAll({ records }) {
      this.getCheckedList(records);
    },
    // 选择页数
    handleCurrentChange(val) {
      this.$emit('pageChange', val);
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  margin-top: 8px;
}
</style>
