/**
 * ====== 单据模块 ======
 * ====== MDM模块 ======
 * =====================
 */
// 客户信息
import CustomerInformation from '@/found/views/mdm/views/customer_information/form/form.vue';
// 终端信息
import TerminalInformation from '@/found/views/mdm/views/terminal_info/form/form.vue';

/**
 * ====== 单据模块 ======
 * ====== SFA模块 ======
 * =====================
 */
// 考勤管理-异常报备明细
import AbormalReport from '@/found/views/sfa/views/attendance_manage/abnormal_report_details/form.vue';
// 考勤管理-请假申请明细
import LeaveApply from '@/found/views/sfa/views/attendance_manage/leave_apply_details/form.vue';
// 考勤管理-工作日调整明细
import WeekDayAdjust from '@/found/views/sfa/views/attendance_manage/weekday_adjust_details/form.vue';
// 考勤管理-出差申请
import BussinessApply from '@/found/views/sfa/views/attendance_manage/business_trip_application/form';
// 考勤管理-加班/值班申请
import WorkOvertimeApply from '@/found/views/sfa/views/attendance_manage/work_overtime/form/form.vue';
// 考勤管理-调休申请
import CommerceSabbaticalApply from '@/found/views/sfa/views/attendance_manage/commerce_sabbatical/form/form.vue';
// 客户对接人变更
import CustomerContactPerson from '@/found/views/sfa/views/attendance_manage/customer_change/form.vue';
// 出货任务
import ShipmentTask from '@/found/views/sfa/views/attendance_manage/shipment_task/form';
// 人员录入
import PersonnelEntry from '@/found/views/sfa/views/attendance_manage/personnel_entry/form';

// 销假申请
import BusinessLeave from '@/found/views/sfa/views/attendance_manage/business_leave/form/form.vue';
// 改变上下级
import ChangeSuperior from '@/found/views/sfa/views/attendance_manage/change_superior/form/form.vue';

// 补打卡申请
import RepairClock from '@/found/views/sfa/views/attendance_manage/repair_clock_application/form';
// 订单申请
import OrderDetail from '@/found/views/sfa/views/order_manage/order_detail/form_detail.vue';
// 方案活动申请
import ActivityProgram from '@/found/views/sfa/views/activity/activity_program/form';
// 直营订单
import DirectlyOrderReport from '@/found/views/sfa/views/order_manage/directly_order_report/form';
// 直营订单收款
import DirectlyOrderReceive from '@/found/views/sfa/views/order_manage/directly_order_receive/form';
// 外聘人员录入
import ExternalEmployees from '@/found/views/sfa/views/order_manage/external_employees/form';
/**
 * ====== cps模块 ======
 */
// 经销商动销提现
import DealerWithdrawal from '@/found/views/cps/views/consumer/consumerScan/form/dealer_withdrawal.vue';
// 异地扫码报备
import RemoteCodeScanning from '@/found/views/dms/views/order_manage/remote_code_scanning_report/form/form.vue';
// 异地扫码报备
import budgetAdjustment from '@/found/views/dms/views/budget_adjustment/form/form.vue';

/**
 * ====== 单据模块 ======
 * ====== TPM酒类模块 ======
 * =====================
 */
// 活动申请
import ActivityApply from '@/found/views/tpm_fine/views/activity_center/activity_manage/form';
// 活动登记
import ActivityRegister from '@/found/views/tpm_fine/views/activity_center/components/register';
// 活动核销
import ActivityWriteOff from '@/found/views/tpm_fine/views/activity_center/activity_write_off/form';

/**
 * ====== 单据模块 ======
 * ====== TPM模块 ======
 * =====================
 */
// 活动中心-项目活动
import MarketingActivity from '@/found/views/tpm/views/activity/marketing_activity/form';
// 活动中心-年度模糊奖励
import AnnualFuzzyAward from '@/found/views/tpm/views/activity/annual_fuzzy_award/form';
// 额外费用申请
import ExtraCost from '@/found/views/tpm/views/budget/extra_cost/form';
// 市场预投入申请
import PreInvestment from '@/found/views/tpm/views/budget/pre_investment/form';
// 市场推广活动项目
import MarketingPromote from '@/found/views/tpm/views/activity/marketing_promote/form';
// 职能部门领用活动
import DepartmentReceiving from '@/found/views/tpm/views/activity/function_department_receiving/form';
// 市场活动复议
import MarketingReconsideration from '@/found/views/tpm/views/activity/marketing_reconsideration/components/reconsideration.js';
// 专卖店申请
import SpecialtyStore from '@/found/views/mdm/views/specialty_store/form';

// 新产品开发申请
import NewProductApply from '@/found/views/mdm/views/new_product_apply/form';

// 产品微调
import ProductAdjustment from '@/found/views/mdm/views/product_adjustment/form';
// 客户终止申请
import TerminalStopApply from '@/found/views/mdm/views/terminal_stop_apply/form';
// 市场活动关闭
import MarketingActivityClose from '@/found/views/tpm/views/activity/marketing_activity_close/components/activity_close.js';

import TerminalAudit from '@/found/views/mdm/views/terminal_audit/form';
import ActivitySupervision from '@/found/views/tpm/views/activity/inspection_center/form';

// 品鉴酒管理
// 品鉴酒管理-品鉴酒订单
import TastingWineOrder from '@/found/views/tpm/views/tasting_wine_management/tasting_wine_order/form';
import project from '../../../tpm/views/activity/project/form';

// 督查中心
// 活动中心-定额活动
import quota from '../../../tpm/views/activity/quota/form';
// 活动中心-实物费用
import practicality from '../../../tpm/views/activity/practicality/form';
// 活动中心-部门费用
import department from '../../../tpm/views/activity/department/form';
// 活动中心-部门领用
import departmentReceive from '../../../tpm/views/activity/department_receive/form';

// 支付中心-活动预付
import actAdvancePay from '../../../tpm/views/pay_center/activity_prepayment/form';
// 支付中心-客户预付
import cusAdvancePay from '../../../tpm/views/pay_center/customer_prepayment/form';
// 支付中心-核销申请
import writeOff from '../../../tpm/views/pay_center/write_off/form/form.vue';
// dms-渠道中心-促销中心-促销政策
import PromotionPolicy from '../../../dms/views/promotion/pro_policy/form';
// dms-渠道中心-订单中心-订单信息
import OrderDetails from '../../../dms/views/order_manage/order_manage_new/form';
// 经销商分利费用明细
import DealerFeeBill from '../../../cps/views/rights/fee/dealer_fee_bill/form';
// 经销商现金解冻明细
import CashUnfreezing from '../../../cps/views/rights/cash/cash_unfreezing/form';
// dms-渠道中心-订单中心-订单信息
import AwardApplication from '../../../dms/views/reward_manage/award_application/form';
// 终端审批 - sfa小程序端

// 西凤 - dms - from
import significanceForm from '../../../../../src/views/dms/views/order/significance/form/form'; // 要货
import needGoodsForm from '../../../../../src/views/dms/views/order/need-goods/form/form'; // 需货
import orderBackForm from '../../../../../src/views/dms/views/order/back/form/form'; // 退换货申请
import anxiousForm from '../../../../../src/views/dms/views/order/anxious/form/form'; // 周急需
import contractForm from '../../../../../src/views/dms/views/contract/form/form'; // 合同
import remittanceForm from '../../../../../src/views/dms/views/bill/remittance/form/form'; // 汇款
import reconciliationForm from '../../../../../src/views/dms/views/bill/reconciliation/form/form'; // 调账
import channelApplyForm from '../../../../../src/views/dms/views/rebate/channel-cost-apply/form/form'; // 费用申请
import lowPriceForm from '../../../../../src/views/dms/views/other/low-price/form/form'; // 低价窜货
import counterfeitingForm from '../../../../../src/views/dms/views/other/counterfeiting/form/form'; // 制假售假
import ContractClosure from '../../../../../src/views/dms/views/bill/change_superior/form/form.vue'; // 合同关闭

const mixin = {
  components: {
    AbormalReport,
    LeaveApply,
    WeekDayAdjust,
    BussinessApply,
    RepairClock,
    OrderDetail,
    ActivityProgram,
    CustomerInformation,
    TerminalInformation,
    ActivityApply,
    ActivityRegister,
    ActivityWriteOff,
    quota,
    practicality,
    project,
    department,
    departmentReceive,
    actAdvancePay,
    cusAdvancePay,
    writeOff,
    PromotionPolicy,
    OrderDetails,
    MarketingActivity,
    ExtraCost,
    PreInvestment,
    MarketingPromote,
    DepartmentReceiving,
    MarketingReconsideration,
    MarketingActivityClose,
    SpecialtyStore,
    NewProductApply,
    ProductAdjustment,
    TerminalStopApply,
    significanceForm,
    needGoodsForm,
    contractForm,
    remittanceForm,
    channelApplyForm,
    orderBackForm,
    anxiousForm,
    reconciliationForm,
    counterfeitingForm,
    lowPriceForm,
    DealerFeeBill,
    CashUnfreezing,
    TerminalAudit,
    DirectlyOrderReport,
    DirectlyOrderReceive,
    CustomerContactPerson,
    ShipmentTask,
    BusinessLeave,
    ChangeSuperior,
    ActivitySupervision,
    WorkOvertimeApply,
    CommerceSabbaticalApply,
    PersonnelEntry,
    AnnualFuzzyAward,
    AwardApplication,
    ExternalEmployees,
    DealerWithdrawal,
    RemoteCodeScanning,
    budgetAdjustment,
    TastingWineOrder,
    ContractClosure,
  },
};

export default mixin;
