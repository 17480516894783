import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import _ from 'xe-utils';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  props: {
    id: String,
  },
  data() {
    return {
      formFunctionCode: 'personnel_entry_form',
      formParentCode: 'CRM20221212000000002',
    };
  },
  async created() {
    await this.getFormRule('personnel_entry_form', 'CRM20221212000000002');
    console.log(this.formConfig);
    if (this.formConfig.row.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      if (this.formConfig.row && this.formConfig.row.id) {
        request.get('/mdm/mdmUserMobileImportController/queryById', {
          id: this.formConfig.row.id,
        }).then((res) => {
          if (res.success) {
            const { result } = res;
            result.attachmentList = [{
              fileName: result.fileName,
              objectName: result.realPath,
              url: result.urlPath,
              urlPath: result.realPath,
            }];
            this.setValue(result);
          }
        });
      }
    },
  },
};
