<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'change_superior',
      formParentCode: 'CRM20211230000000002',
      rule: [],
      buttons: {
        submit: false,
      },
    };
  },
  async created() {
    this.getDetail();
  },

  methods: {
    // 获取详情
    getDetail() {
      const requestUrl = '/mdm/customerSuperiorChangeController/query';
      request.get(requestUrl, { id: this.formConfig.row.id }).then((res) => {
        if (res.success) {
          const newData = res.result;
          newData.oldName = newData.versionRespVo.expectSuperiorRealName;
          newData.oldPosition = newData.versionRespVo.expectSuperiorPosName;
          newData.oldOrg = newData.versionRespVo.expectSuperiorOrgName;
          newData.oldCompany = newData.versionRespVo.companyName;
          this.setValue(newData);
        }
      });
    },
  },
};
</script>
