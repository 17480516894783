var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c("p", { staticClass: "table-title" }, [_vm._v("历史任务")]),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "vxe-table",
            {
              ref: "xTable",
              attrs: {
                border: "",
                "show-header-overflow": "",
                "show-overflow": "",
                "highlight-hover-row": "",
                data: _vm.taskList,
              },
              on: { "radio-change": _vm.changeBox },
            },
            [
              _c("vxe-table-column", {
                attrs: { type: "radio", width: "40", fixed: "left" },
              }),
              _vm._l(_vm.columns, function (col, index) {
                return _c("vxe-table-column", {
                  key: index,
                  staticStyle: { height: "36px" },
                  attrs: {
                    field: col.field,
                    title: col.title,
                    "min-width": col.width ? col.width : 80,
                    fixed: col.fixed,
                    "header-class-name": "header-col",
                  },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-form",
        { ref: "ruleForm", attrs: { model: _vm.ruleForm, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "回退意见", prop: "remark" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "remark", $$v)
                  },
                  expression: "ruleForm.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                loading: _vm.$store.state.buttonApiIsLoading,
              },
              on: { click: _vm.submit },
            },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.close } },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }