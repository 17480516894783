<template>
  <div>
    <!-- 弹窗form -->

    <el-drawer custom-class="form_drawer"
               :title="drawerTitle"
               size="80%"
               :visible.sync="formShow"
               @closeDrawer="closeDrawer"
               :close-on-press-escape="false"
               :modal-append-to-body="false">
      <!-- 表单 -->
      <DrawerComponent ref="drawer"
                       :button="drawerButton"
                       :loading="loading"
                       @colse="formShow = false"
                       :drawerConfig="drawerConfig"
                       @save="requestSave">
          <div v-if="row&&row.ext37==='Y'&&row.authorizationCode">
            <div style="font-size: 16px;height: 20px;line-height: 20px;font-weight: 600;padding: 20px 0;">授权委托书</div>
            <div style="margin:50px 50px 0 50px;">
              <div style="text-align: right;font-size:18px;margin-right:50px;">(编号：<span style="font-size:18px;">{{row.authorizationCode}}</span>)</div>
              <div v-if="!(row.authorizationCode.split('-')[1].length === 3 &&row.authorizationCode.split('-')[1]<='620')">
                <p style="font-size:16px;margin-bottom: 40px;">兹委托<span style="font-size:16px;"> {{row.cusName}} </span> 销售西凤酒系列产品，产品明细及其销售区域以经销商合同为准。</p>
              </div>
              <div v-else>
                <p style="font-size:16px;margin-bottom: 40px;">兹委托<span style="font-size:16px;"> {{row.cusName}} </span> 销售西凤酒系列产品，销售区域以经销商合同为准。</p>
              </div>
              <p style="margin-left:60px;font-size:16px;margin-bottom: 40px;">有效期为<span style="font-size:16px;"> {{row.authorizationStartTime}} </span>至<span style="font-size:16px;"> {{row.authorizationEndTime}} </span></p>
              <p style="margin-left:40px;font-size:16px;">特此委托</p>
              <p style="text-align: right;font-size:16px;margin-right:50px;"><span style="font-size:16px;">{{row.ext16}}</span></p>
              <p style="text-align: right;font-size:16px;margin-right:50px;"><span style="font-size:16px;">{{row.authorizationTime}}</span></p>
            </div>
          </div>
        <div slot="definedBtn"
             v-if="code == 'change_person'">
          <el-button type="primary"
                     :loading="loading"
                     @click="confirmChange">确定变更</el-button>
        </div>
      </DrawerComponent>
      <!-- 弹窗商品列表 -->
      <div>
        <el-drawer custom-class="form_drawer"
                   title="添加商品"
                   size="80%"
                   :visible.sync="drawerShowProduct"
                   :modal="false"
                   :close-on-press-escape="false"
                   :append-to-body="true">
          <div class="drawer-table">
            <TableComponent v-if="drawerShowProduct"
                            @searchData="searchProduct"
                            :pagination="tablePaginationProduct"
                            :templateData="tableDataProduct"
                            :table-loading="tableLoadingProduct"
                            :searchConfig="searchConfigProduct"
                            :table-config="tableConfigProduct"></TableComponent>
          </div>
          <div class="drawer-button">
            <buttonComponent @clickEvent="beforeSaveSelect"
                             :btnType="'primary'"
                             :icon="'el-icon-check'">保存
            </buttonComponent>
            <buttonComponent @clickEvent="drawerShowProduct = false"
                             :btnType="'danger'"
                             :icon="'el-icon-close'">关闭
            </buttonComponent>
          </div>
        </el-drawer>
      </div>
      <!-- 客户列表 -->
      <div>
        <el-drawer custom-class="form_drawer"
                   title="选择客户"
                   size="80%"
                   :visible.sync="drawerShowCustomer"
                   :modal="false"
                   :close-on-press-escape="false"
                   :append-to-body="true">
          <div class="drawer-table">
            <TableComponent @searchData="searchCustomer"
                            :pagination="tablePaginationCustomer"
                            :templateData="tableDataCustomer"
                            :table-loading="tableLoadingCustomer"
                            :searchConfig="searchConfigCustomer"
                            :table-config="tableConfigCustomer"></TableComponent>
          </div>
          <div class="drawer-button">
            <buttonComponent @clickEvent="beforeSaveSelect"
                             :btnType="'primary'"
                             :icon="'el-icon-check'">保存
            </buttonComponent>
            <buttonComponent @clickEvent="drawerShowCustomer = false"
                             :btnType="'danger'"
                             :icon="'el-icon-close'">关闭
            </buttonComponent>
          </div>
        </el-drawer>
      </div>
    </el-drawer>
     <!-- 合同列表-->
     <div>
        <el-drawer
                    v-if="drawerShowContract"
                   title="合同列表"
                   size="80%"
                   :visible.sync="drawerShowContract"
                   :modal="false"
                   @close="colseCon"
                   style="z-index: 19999 !important;"
                   :close-on-press-escape="false"
                   :append-to-body="false">
          <div class="drawer-table">
            <TableComponent @searchData="searchContract"
                            :pagination="tablePaginationContract"
                            :templateData="tableDataContract"
                            :table-loading="tableLoadingContract"
                            :searchConfig="searchConfigContract"
                            :table-config="tableConfigContract"></TableComponent>
          </div>
        </el-drawer>
      </div>

    <DrawerComponent v-if="dmsId"
                     ref="drawer"
                     :button="drawerButton"
                     :loading="loading"
                     @colse="formShow = false"
                     :close-on-press-escape="false"
                     :drawerConfig="drawerConfig"
                     @save="requestSave">
    </DrawerComponent>
    <div>
      <a-drawer
          :visible="chshow"
          class="custom-class"
          root-class-name="root-class-name"
          :keyboard="false"
          title="查看详情"
          :headerStyle="{ fontSize: '12px',fontWeight:400}"
          width="80%"
          placement="right"
          :z-index="4000"
          @close="chshow=false"
        >
        <FormCopy :dmsCode="dataCodeCopy" :dmsId="idCopy"></FormCopy>
        </a-drawer>

    </div>
  </div>

</template>

<script>
import _ from 'xe-utils';
import buttonComponent from '../../../compoment/button-component/button-component';
import TableComponent from '../../../compoment/table-component/table-component.vue';
import DrawerComponent from '../../../compoment/drawer-component/drawer-component';
import FormCopy from './form_copy.vue';

// import FormPage from '.';
// 新增表单
export default {
  name: 'form-component',
  props: ['dmsId', 'dmsCode'],
  components: {
    TableComponent,
    DrawerComponent,
    buttonComponent,
    FormCopy,
  },
  data() {
    return {
      row: {},
      chshow: false,
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      selectTableData: [], // table列表选中的数据
      dataCode: '',
      id: '',
      loading: false,
      code: 'add',
      userList: [],
      // ---------------------- 弹窗 - 合同新增表单配置
      formShow: false,
      drawerTitle: '新增',
      drawerButton: {
        show: true,
        options: [
          {
            show: false,
            text: '暂存',
            fn: () => {
              this.requestSave(undefined, 'Y');
            },
          },
        ],
      },
      drawerConfig: [
        // 弹窗组件配置
        {
          title: '基本信息',
          type: 'form',
          ruleForm: {
            orderSourceType: 'back',
            code: '',
            cusName: '',
            ext20: '',
            ext18: '',
            ext2: '',
            contractType: '',
            ext12: '',
            ext1: '',
            ext14: '',
            ext31: '',
            orgName: '', // 组织名称
            branchOfficeName: '', // 分公司名称
            ext21: 0,
            ext22: '',
            ext4: '',
            ext5: '',
            ext6: '',
            ext7: '',
            startTime: '',
            endTime: '',
            ext8: '',
            ext9: '',
            ext3: '',
            remarks: '',
            ext11: '',
            liabilityForBreach: '',
            contractDetails: '',
            fileVos: '',
            dateRange: '',
            ext36: '', // 销售渠道
          },
          formConfig: {
            // 弹窗表单配置
            disabled: false,
            items: [
              [
                {
                  label: '合同编号',
                  key: 'code',
                  disabled: true,
                },
                {
                  label: '客户名称',
                  key: 'cusName',
                  valueType: 'iconInput',
                  icon: 'el-icon-circle-plus-outline',
                  fn: () => {
                    this.searchCustomer({});
                  },
                },
                {
                  valueType: 'look',
                  show: false,
                  disabled: false,
                  fn: (row) => {
                    console.log('点击', row);
                    this.drawerShowContract = true;
                    setTimeout(() => {
                      this.searchContract({});
                    }, 1000);
                  },
                },
                {
                  label: '分公司',
                  key: 'ext20',
                  show: false,
                },
                {
                  label: '所属业务部门',
                  key: 'ext18',
                  show: false,
                },
                {
                  label: '签订日期',
                  key: 'ext2',
                  valueType: 'date',
                },
                {
                  label: '合同类型',
                  key: 'contractType',
                  valueType: 'select',
                  path: 'code?dictTypeCode=contract_type_xf',
                  fn: (val) => {
                    // 合同类型不为增补合同清除主合同字段数据
                    if (val === '1' || val === '3') {
                      this.setFormData(null, 'ext12');
                      this.setFormData(null, 'dateRange');
                    }
                    // 合同类型为增补合同
                    this.setFormItemValue(['ext12'], 'show', val === '2');
                    this.setFormItemValue(
                      ['ext1', 'ext14', 'startTime', 'endTime', 'dateRange', 'ext36'],
                      'disabled',
                      val === '2',
                    );
                    this.setDrawerValue('主合同商品', 'show', val === '2');
                    console.log('444');
                  },
                },
                {
                  label: '主合同',
                  key: 'ext12',
                  valueType: 'select',
                  show: false,
                  options: {
                    value: 'code',
                    label: 'name',
                  },
                  fn: (val) => {
                    this.getContractProduct(val);
                  },
                },
                {
                  valueType: 'select',
                  label: '公司主体',
                  key: 'ext1',
                  path: 'companyPageList?flagBan=1',
                  options: {
                    label: 'companyName',
                    value: 'companyCode',
                  },
                  fn: (value, formData, ref) => {
                    this.$nextTick(() => {
                      this.setFormData(ref[0].selectedLabel, 'ext16');
                      this.deleteProduct('all', 1);
                    });
                  },
                },
                {
                  label: '负责人',
                  key: 'ext14',
                  valueType: 'select',
                  options: {
                    value: 'positionCode',
                    label: 'fullName',
                  },
                  fn: (val, formData, ref, selectData) => {
                    console.log(val, formData, ref, selectData, 444);
                    this.setPeople(val, selectData);
                  },
                },
                {
                  label: '职位',
                  disabled: true,
                  key: 'ext31',
                },
                {
                  label: '负责人组织',
                  disabled: true,
                  key: 'orgName',
                },
                {
                  label: '负责人分公司',
                  disabled: true,
                  key: 'branchOfficeName',
                },
                {
                  label: '合同金额',
                  key: 'ext21',
                  disabled: true,
                },
                {
                  label: '保证金(万)',
                  key: 'ext22',
                  inputType: 'number',
                },
                {
                  label: '是否为制式合同',
                  key: 'ext4',
                  valueType: 'select',
                  path: 'code?dictTypeCode=yesOrNo',
                },
                {
                  label: '汇款方式',
                  key: 'ext5',
                  valueType: 'select',
                  path: 'code?dictTypeCode=pay_type_xf',
                },
                {
                  label: '是否是临时合同',
                  key: 'ext6',
                  valueType: 'select',
                  path: 'code?dictTypeCode=yesOrNo',
                },
                {
                  label: '销售指标',
                  key: 'ext7',
                },
                {
                  label: '合同有效时间',
                  key: 'dateRange',
                  valueType: 'daterange',
                },
                // {
                //   label: '是否收到纸质合同',
                //   key: 'ext8',
                //   valueType: 'select',
                //   path: 'code?dictTypeCode=yesOrNo',
                // },
                {
                  label: '客户经营商品类型',
                  key: 'ext9',
                  valueType: 'select',
                  path: 'code?dictTypeCode=product_type',
                },
                {
                  label: '销售渠道',
                  key: 'ext36',
                  valueType: 'select',
                  path: 'code?dictTypeCode=dms_sales_channel',
                },
                {
                  label: '合同状态',
                  key: 'ext3',
                  show: false,
                  valueType: 'select',
                  path: 'code?dictTypeCode=contract_status',
                },
                {
                  label: '备注',
                  key: 'remarks',
                  valueType: 'textarea',
                  itemWidth: '100%',
                },
                {
                  label: '审批状态',
                  key: 'ext11',
                  disabled: true,
                  show: false,
                },
                {
                  // 暂时保留 确认需求后可删除
                  key: 'liabilityForBreach',
                  valueType: 'textarea',
                  label: '违约责任',
                  itemWidth: '100%',
                  show: false,
                },
                {
                  // 暂时保留 确认需求后可删除
                  valueType: 'textarea',
                  key: 'contractDetails',
                  label: '合同细则',
                  itemWidth: '100%',
                  show: false,
                },
                {
                  label: '附件',
                  key: 'filesVo',
                  itemWidth: '100%',
                  valueType: 'inputUpload',
                  uploadConfig: {
                    accept: 'pdf,docx,xlsx,ppt,png,jpg',
                  },
                },
              ],
            ],
          },
          rules: {
            name: [
              {
                required: true,
                message: '请输入合同名称',
                trigger: 'blur',
              },
            ],
            ext12: [
              {
                required: true,
                message: '请选择主合同',
                trigger: 'change',
              },
            ],
            contractDetails: [
              {
                required: true,
                message: '请输入合同细责',
                trigger: 'blur',
              },
            ],
            liabilityForBreach: [
              {
                required: true,
                message: '请输入违约责任',
                trigger: 'blur',
              },
            ],
            cusName: [
              {
                required: true,
                message: '请选择客户',
                trigger: 'change',
              },
            ],
            ext1: [
              {
                required: true,
                message: '请选择公司主体',
                trigger: 'change',
              },
            ],
            ext2: [
              {
                required: true,
                message: '请选择签订日期',
                trigger: 'change',
              },
            ],
            contractType: [
              {
                required: true,
                message: '请选择合同类型',
                trigger: 'change',
              },
            ],
            ext22: [
              {
                required: true,
                message: '请输入保证金',
                trigger: 'blur',
              },
            ],
            ext36: [
              {
                required: true,
                message: '请选择销售渠道',
                trigger: 'change',
              },
            ],
            dateRange: [
              {
                required: true,
                message: '请选择合同有效时间',
                trigger: 'change',
              },
            ],
            ext14: [
              {
                required: true,
                message: '请选择负责人',
                trigger: 'change',
              },
            ],
          },
        },
        {
          title: '可购商品',
          type: 'table',
          tableMergeData: [],
          tableConfig: {
            disabled: false,
            objectSpanMethod: ({ rowIndex, columnIndex }) => this.objectSpanMethod({
              rowIndex,
              columnIndex,
              drawConfigIndex: 1,
            }),
            rows: [
              {
                label: '商品编码',
                prop: 'productCode',
                width: 150,
                fixed: true,
              },
              {
                label: '商品名称',
                prop: 'productName',
                width: 250,
                fixed: true,
              },
              {
                label: '规格',
                prop: 'spec',
                width: 120,
              },
              {
                label: '数量',
                prop: 'num',
                type: 'input',
                width: 120,
                fn: this.inputNumber,
                change: (e) => {
                  if (Number(e.num) % Number(e.unitConversion) !== 0) {
                    this.$message.error('当前输入商品数量不是商品规格整倍数，请重新输入！');
                  }
                },
              },
              {
                label: '商品单价',
                prop: 'productPrice',
                type: 'input',
                width: 120,
                inputType: 'number',
                fn: (e) => {
                  this.calculateDiscountAmount(e);
                },
                change: (e) => {
                  if (parseFloat(e.productPrice) < e.price) {
                    this.$message.error('价格不正确，请核实后再填写');
                  } else if (e.isQuotaProduct === '1') {
                    this.quotaSearchPrice(e);
                  }
                },
              },
              {
                label: '配额外价格',
                prop: 'quotaPrice',
                width: 120,
              }, {
                label: '折扣类型',
                prop: 'discountType',
                disabled: true,
                width: 150,
                type: 'select',
                path: 'code?dictTypeCode=ins_type',
                // fn: (e, value) => {
                //   // value  {0: 折扣率 1: 固定折扣}
                //   if (value === '1') {
                //     e.discountRate = '';
                //   } else {
                //     e.discountPrice = '';
                //   }
                //   e.discountAfterPrice = e.productPrice;
                //   this.calculateProductTotalPrice(e);
                // },
              },
              {
                label: '折扣率',
                prop: 'discountRate',
                width: 120,
                disabled: true,
              },
              {
                label: '折扣',
                prop: 'discountPrice',
                width: 120,
                inputType: 'number',
                disabled: true,
              },
              {
                label: '折后金额',
                prop: 'discountAfterPrice',
                width: 120,
                disabled: true,
              },
              {
                label: '总金额',
                prop: 'productTotalPrice',
                width: 120,
              },
              {
                label: '数量从',
                prop: 'fromPriceNumber',
                width: 120,
                disabled: true,
              },
              {
                label: '数量到',
                prop: 'toPriceNumber',
                width: 120,
                disabled: true,
              },
              {
                label: '销售区域',
                prop: 'saleArea',
                width: 300,
                type: 'saleArea',
                props: {
                  children: 'childList',
                  label: 'regionName',
                  value: 'regionName',
                  multiple: true,
                  checkStrictly: true,
                },
              },
              {
                label: '状态',
                prop: 'delFlagName',
                width: 150,
              },
              {
                label: '销售区域',
                prop: 'saleAreaName',
                width: 300,
              },

            ],
            rowButtons: {
              items: [
                {
                  show: true,
                  type: 'default',
                  icon: 'el-icon-delete',
                  fn: (row) => {
                    this.deleteProduct(row, 1);
                  },
                },
              ],
            },
          },
          tableData: [],
          tableButtons: [
            {
              title: '添加商品',
              fn: () => {
                const { ruleForm } = this.drawerConfig[0];
                if (ruleForm.contractType === '2') {
                  // 合同类型为增补合同
                  // eslint-disable-next-line no-unused-expressions
                  ruleForm.ext12
                    ? this.searchProduct({})
                    : this.$message.error('请先选择主合同');
                } else {
                  this.searchProduct({});
                }
              },
            },
            {
              title: '清空',
              fn: (e) => {
                this.deleteProduct('all', 1);
              },
            },
          ],
        },
        {
          title: '主合同商品',
          type: 'table',
          show: false,
          tableConfig: {
            disabled: true,
            objectSpanMethod: ({ rowIndex, columnIndex }) => this.objectSpanMethod({
              rowIndex,
              columnIndex,
              drawConfigIndex: 2,
            }),
            rows: [
              {
                label: '商品编码',
                prop: 'productCode',
                width: 150,
                fixed: true,
              },
              {
                label: '商品名称',
                prop: 'productName',
                width: 250,
                fixed: true,
              },
              {
                label: '规格',
                prop: 'spec',
                width: 120,
              },
              {
                label: '数量',
                prop: 'num',
                width: 120,
              },
              {
                label: '商品单价',
                prop: 'productPrice',
                width: 120,
              },
              {
                label: '折扣类型',
                prop: 'discountType',
                type: 'select',
                path: 'code?dictTypeCode=ins_type',
                width: 150,
              },
              {
                label: '折扣率',
                prop: 'discountRate',
                width: 120,
              },
              {
                label: '折扣',
                prop: 'discountPrice',
                width: 120,
              },
              {
                label: '折后金额',
                prop: 'discountAfterPrice',
                width: 120,
              },
              {
                label: '总金额',
                prop: 'productTotalPrice',
                width: 120,
              },
              {
                label: '数量从',
                prop: 'fromPriceNumber',
                width: 120,
              },
              {
                label: '数量到',
                prop: 'toPriceNumber',
                width: 120,
              },
              {
                label: '销售区域',
                prop: 'saleArea',
                width: 300,
                type: 'saleArea',
                props: {
                  children: 'childList',
                  label: 'regionName',
                  value: 'regionName',
                  multiple: true,
                  checkStrictly: true,
                },
              },
              {
                label: '销售区域',
                prop: 'saleAreaName',
                width: 300,
              },
            ],
            tableMergeData: [],
          },
          tableData: [],
        },
      ],

      //  ----------------------- 弹窗 - 商品列表配置
      drawerShowProduct: false,
      tableLoadingProduct: false,
      tableDataProduct: [],
      tableConfigProduct: {
        checkbox: true,
        isSaveSelect: true,
        checkboxFn: this.selectListData,
        rows: [
          {
            label: '商品编码',
            prop: 'materialCode',
          },
          {
            label: '商品名称',
            prop: 'materialName',
          },
        ],
      }, // 列表配置
      tablePaginationProduct: {},
      searchConfigProduct: {
        options: [
          {
            valueName: 'materialCode',
            placeholder: '请输入商品编号',
          },
          {
            valueName: 'materialName',
            placeholder: '请输入商品名称',
          },
        ],
      },

      //  ----------------------- 弹窗 - 客户列表配置
      drawerShowCustomer: false,
      tableLoadingCustomer: false,
      tableDataCustomer: [],
      tableConfigCustomer: {
        checkbox: true,
        checkboxFn: this.selectListData,
        rows: [
          {
            label: '客户名称',
            prop: 'customerName',
          },
          {
            label: '客户编码',
            prop: 'customerCode',
          },
          {
            label: '客户类型',
            prop: 'customerTypeName',
          },
        ],
      },
      tablePaginationCustomer: {},
      searchConfigCustomer: {
        options: [
          {
            valueName: 'customerName',
            placeholder: '请输入客户名称',
          },
          {
            valueName: 'customerCode',
            placeholder: '请输入客户编码',
          },
          {
            valueName: 'customerType',
            placeholder: '请选择客户类型',
            valueType: 'select',
            path: 'code?dictTypeCode=cust_type',
          },
        ],
      },
      //  ----------------------- 弹窗 - 合同列表配置
      drawerShowContract: false,
      tableLoadingContract: false,
      tableDataContract: [],
      tableConfigContract: {
        checkbox: false,
        checkboxFn: [],
        rows: [
          {
            label: '合同编码',
            prop: 'code',
            width: 120,
            color: '#c34231',
            fn: (e) => {
              console.log('点击了详情', e);
              this.dataCodeCopy = e.code;
              this.idCopy = e.id;
              this.chshow = true;
            },
          },
          {
            label: '签订日期',
            prop: 'ext2',
          },
          {
            label: '合同类型',
            prop: 'contractType',
            disabled: true,
            width: 120,
            type: 'select',
            path: 'code?dictTypeCode=contract_type_xf2',
          },

          {
            label: '公司主体',
            prop: 'ext16',
          },
          {
            label: '负责人',
            prop: 'ext13',
          },
          {
            label: '合同金额',
            prop: 'ext21',
          },
          {
            label: '保证金(万元)',
            prop: 'ext22',
          },
          {
            label: '是否制式合同',
            prop: 'ext4',
            disabled: true,
            type: 'select',
            placeholder: '否',
            path: 'code?dictTypeCode=yesOrNO',

          },
          {
            label: '销售渠道',
            prop: 'ext36',
            disabled: true,
            width: 120,
            type: 'select',
            path: 'code?dictTypeCode=dms_sales_channel',
          },
          {
            label: '合同状态',
            prop: 'ext3',
            width: 120,
            disabled: true,
            type: 'select',
            path: 'code?dictTypeCode=contract_status',
          },
          {
            label: '审批状态',
            prop: 'ext11',
            width: 120,
            disabled: true,
            type: 'select',
            path: 'code?dictTypeCode=dms_approval_state',
          },
          {
            label: '产品名称',
            width: 300,
            prop: 'productNames',
          },
        ],
      },
      tablePaginationContract: {},
      searchConfigContract: {
        options: [

          {
            valueName: 'code',
            placeholder: '请输入合同编码',
          },
          {
            valueName: 'ext16',
            placeholder: '请输入公司主体',
          },
          {
            valueName: 'ext13',
            placeholder: '请输入负责人',
          },
          {
            valueName: 'productName',
            placeholder: '请输入产品名称',
          },
          {
            valueName: 'contractType',
            placeholder: '请选择合同类型',
            valueType: 'select',
            path: 'code?dictTypeCode=contract_type_xf2',
          },
          {
            valueName: 'ext36',
            placeholder: '请选择销售渠道',
            valueType: 'select',
            path: 'code?dictTypeCode=dms_sales_channel',
          },
          {
            valueName: 'ext3',
            placeholder: '请选择合同状态',
            valueType: 'select',
            path: 'code?dictTypeCode=contract_status',
          },
          {
            valueName: 'ext11',
            placeholder: '请选择审批状态',
            valueType: 'select',
            path: 'code?dictTypeCode=dms_approval_state',
          },
          {
            valueName: 'signStartTime',
            placeholder: '请选择签订开始日期',
            valueType: 'date',
          },
          {
            valueName: 'signEndTime',
            placeholder: '请选择签订结束日期',
            valueType: 'date',

          },
        ],
      },
      backData: {},
      dataCodeCopy: '',
      idCopy: '',
    };
  },
  created() {
    this.initPageState();
    /**
     * 以下两行未了处理
     * bpm待办事项引入组件查询详情 （审批流）
     */
    this.id = this.dmsId || this.id;
    this.code = this.dmsCode || this.code;
    const items = this.drawerConfig[0].formConfig.items[0];
    items[0].show = this.code !== 'add';

    console.log(this.dmsId);
    console.log(this.dmsCode);
    console.log(this.id);
    if (this.row.code || this.id || this.row.id) {
      items[2].show = this.code === 'view';
    }

    console.log(this);
  },
  watch: {
    id(val) {
      if (val) {
        this.searchDetail();
      }
    },
  },
  methods: {
    onGetSelect(val) {
      console.log(val);
    },
    handleKeydown(event) {
      console.log(event);
    },
    // 确定变更
    async confirmChange() {
      const formData = this.drawerConfig[0].ruleForm;
      if (!formData.ext13) {
        this.$message.error('请选择负责人');
        return;
      }
      console.log(this.drawerConfig, 222);
      const prams = {
        id: formData.id,
        ext13: formData.ext13, // 名称
        ext14: formData.ext14, // 编码
        ext15: formData.ext15, // 职位编码
        ext31: formData.ext31, // 职位名称
        orgName: formData.orgName, // 组织名称
        branchOfficeName: formData.ext1, // 分公司名称
      };
      const url = '/dms/dms/contract/updatePrincipal';
      this.loading = true;
      try {
        await this.$http.post(url, prams);
        this.$message.success('变更成功');
        this.loading = false;
        this.formShow = false;
        this.getParentTable();
      } catch (e) {
        this.loading = false;
      }
    },
    initPageState() {
      const itemFormNames = [
        'state',
        'isScan',
        'ext3',
        'applyStatus',
        'responseBranchName',
        'createDate',
      ];
      if (this.code === 'view') {
        // 设置查看显示的字段
        this.setFormItemValue(itemFormNames, 'show', true);
      } else {
        this.setFormItemValue(itemFormNames, 'show', false);
      }

      if (this.code === 'view' || this.dmsId) {
        this.drawerConfig[1].tableConfig.rows = this.drawerConfig[1].tableConfig.rows.filter((v) => v.prop !== 'saleArea');
        this.drawerConfig[2].tableConfig.rows = this.drawerConfig[2].tableConfig.rows.filter((v) => v.prop !== 'saleArea');
      } else {
        this.drawerConfig[1].tableConfig.rows = this.drawerConfig[1].tableConfig.rows.filter((v) => v.prop !== 'saleAreaName');
        this.drawerConfig[2].tableConfig.rows = this.drawerConfig[2].tableConfig.rows.filter((v) => v.prop !== 'saleAreaName');
      }

      if (this.code !== 'add') {
        // 非新增查询详情
        this.searchDetail();
        this.setFormItemValue(['contractType'], 'disabled', true);
      }
    },

    /**
     * 合并列
     * */
    objectSpanMethod({ rowIndex, columnIndex, drawConfigIndex }) {
      const { tableMergeData, tableData } = this.drawerConfig[drawConfigIndex];
      const mergeIndexs = [0, 1, 2, 3];
      if (mergeIndexs.includes(columnIndex)) {
        const mergeIndex = tableMergeData.indexOf(rowIndex);
        if (mergeIndex !== -1) {
          let rowspan;
          if (mergeIndex + 1 === tableMergeData.length) {
            // 最后一个
            rowspan = tableData.length - tableMergeData[mergeIndex];
          } else if (tableMergeData.length !== 1) {
            // 多条数据且 非第一个
            rowspan = tableMergeData[mergeIndex + 1] - tableMergeData[mergeIndex];
          }
          return {
            rowspan,
            colspan: 1,
          };
        }

        return {
          rowspan: 0,
          colspan: 0,
        };
      }
    },

    /**
     * 设置负责人相关信息
     * @param val {object} 选择的数据
     * @param selectData {object} 下拉框所有数据
     */
    setPeople(val, selectData) {
      console.log(val, selectData, 111);
      selectData.forEach((i) => {
        if (i.positionCode === val) {
          this.setFormData(i.fullName, 'ext13');
          this.setFormData(i.positionCode, 'ext15');
          this.setFormData(i.positionName, 'ext31');
          this.setFormData(i.orgName, 'orgName');
          this.setFormData(i.ext1, 'branchOfficeName');
        }
      });
    },

    /**
     * 设置禁用
     * @param state {boolean}
     */
    setDisabled(state) {
      this.$nextTick(() => {
        this.drawerConfig[0].formConfig.disabled = state;
        this.drawerConfig[1].tableConfig.disabled = state;
        this.drawerConfig[1].tableConfig.rowButtons.show = !state;
        this.drawerButton.show = !state;
        if (this.code === 'remove_product_row') {
          // 移除商品
          this.drawerConfig[1].tableConfig.rowButtons.show = true;
        }
        if (this.code === 'change_person') {
          const tempData = this.drawerConfig[0].formConfig.items || [];
          const formDat = tempData[0];
          const tempArr = [];
          formDat.forEach((item) => {
            if (item.key !== 'ext14') {
              tempArr.push(item.key);
            }
          });
          this.setFormItemValue(tempArr, 'disabled', true);
          this.drawerConfig[0].formConfig.disabled = false;
        }
      });
    },

    /**
     * 查询详情
     */
    async searchDetail() {
      if (this.id || this.dataCode) {
        const { result } = await this.$http.post('/dms/dms/contract/query', {
          code: this.dataCode,
          id: this.id,
        });
        await this.searchDocking(result.cusCode);
        const date1 = new Date(result.createDate);
        const data2 = new Date('2023-11-01');
        if (date1 < data2) {
          const items = this.drawerConfig[0].formConfig.items[0];
          items[5].path = 'code?dictTypeCode=contract_type_xf2';
          items[5].data = [{ dictCode: '1', dictValue: '合同' }, { dictCode: '2', dictValue: '增补合同' }, { dictCode: '3', dictValue: 'B类合同' }];
          items[5].disabled = false;
          this.drawerConfig[0].formConfig.items[0] = items;
        }
        result.ext14 = ((this.userList || []).find((v) => v.positionCode === result.ext15) || {}).positionCode || '';
        if (this.code === 'view') {
          result.orgName = result.ext18 || '';
          result.branchOfficeName = result.ext20 || '';
        } else {
          result.orgName = ((this.userList || []).find((v) => v.positionCode === result.ext15) || {}).orgName || '';
          result.branchOfficeName = ((this.userList || []).find((v) => v.positionCode === result.ext15) || {}).ext1 || '';
        }
        result.dateRange = [result.startTime, result.endTime];
        this.drawerConfig[0].ruleForm = { ...result };
        this.backData = { ...result };
        result.productList.map((item) => {
          item.saleAreaName = item.saleArea;
          item.saleArea = item.saleArea ? item.saleArea.split(',') : [];
          return item;
        });
        result.productList.map((item) => {
          item.saleArea = item.saleArea ? (item.saleArea || []).map((i) => i.split('/')) : [];
          return item;
        });
        this.drawerConfig[1].tableData = result.productList;
        this.mergeTable(1);

        if (result.contractType === '2') {
          // 增补合同
          this.drawerConfig[2].show = true;
          this.setFormItemValue(['ext12'], 'show', true);
          this.setFormItemValue(
            ['ext1', 'ext14', 'startTime', 'endTime', 'ext36'],
            'disabled',
            true,
          );
          await this.searchPrimaryContract(result.cusCode);
          await this.getContractProduct(result.code);
        }
        this.setDisabled(
          this.code === 'view'
          || this.code === 'remove_product_row'
          || this.code === 'change_person',
        );
        this.closeDrawer('drawerShowContract');
      }
    },

    /**
     * 查询主合同商品
     * @param code {string}
     * @returns {Promise<void>}
     * */
    async getContractProduct(code) {
      const { result } = await this.$http.post('/dms/dms/contract/query', {
        code,
      });
      if (this.code === 'edit' || this.code === 'view') {
        this.setDrawerValue(
          '主合同商品',
          'tableData',
          result.productMainList || [],
        );
      } else {
        this.setDrawerValue('主合同商品', 'tableData', result.productList);
      }
      this.mergeTable(2);
      console.log((this.userList || []).find((v) => v.positionCode === result.ext15));
      result.ext14 = ((this.userList || []).find((v) => v.positionCode === result.ext15) || {}).positionCode || '';
      const setNames = ['dateRange', 'ext14', 'ext13', 'ext1', 'ext16', 'ext31', 'ext15', 'ext36', 'orgName', 'branchOfficeName'];
      setNames.forEach((i) => {
        // eslint-disable-next-line no-unused-expressions
        i !== 'dateRange'
          && this.$set(this.drawerConfig[0].ruleForm, i, result[i]);
        // eslint-disable-next-line no-unused-expressions
        i === 'dateRange'
          && this.$set(this.drawerConfig[0].ruleForm, i, [
            result.startTime,
            result.endTime,
          ]);
      });
    },

    /**
     * 查询客户（经销商）列表
     * @param requestData {Object}
     * @returns {Promise<void>}
     */
    async searchCustomer(requestData) {
      this.tableLoadingCustomer = true;
      this.showDrawer('drawerShowCustomer');
      const { positionCode } = this.userInfo;
      const params = {
        pageSize: 10,
        actApproveStatus: '3',
        positionCode,
        ...requestData,
      };
      const {
        result: { data, count },
      } = await this.$http.post(
        '/mdm/mdmCustomerMsgExtController/pageList',
        params,
      );
      this.tableDataCustomer = data;
      this.tableLoadingCustomer = false;
      this.tablePaginationCustomer.total = count;
    },

    async searchContract(requestData) {
      console.log(this);
      console.log(requestData);
      this.tableLoadingContract = true;
      this.showDrawer('drawerShowContract');
      const params = {
        pageSize: 10,
        cusName: this.backData.cusName,
        custContractFlag: '1',
        ...requestData,
      };
      if (requestData.ext3 || requestData.ext11) {
        delete params.custContractFlag;
      }
      const {
        result: { data, count },
      } = await this.$http.post(
        '/dms/dms/contract/custContractPageList',
        params,
      );
      this.tableDataContract = data;
      this.tableLoadingContract = false;
      this.tablePaginationContract.total = count;
    },
    async colseCon() {
      console.log('关闭');
      this.searchContract({});
      this.closeDrawer('drawerShowContract');
    },
    /**
     * 查询商品列表
     * @param requestData {Object}
     * @returns {Promise<void>}
     */
    async searchProduct(requestData) {
      this.tableLoadingProduct = true;
      const productCodes = this.drawerConfig[1].tableData.map(
        (i) => i.productCode,
      ); // 合同保存的商品
      const productCodes1 = this.drawerConfig[2].tableData.map(
        (i) => i.productCode,
      ); // 主合同保存的商品
      const productCodes2 = await this.filterProduct(); // 非当前负责人的商品
      const params = {
        pageSize: 10,
        ...requestData,
        excludeTypeList: ['促销品'],
        excludeList: [...productCodes, ...productCodes1, ...productCodes2],
        isShelf: 'Y',
      };
      const {
        result: { data, count },
      } = await this.$http.post(
        '/mdm/mdmMaterialExtController/pageList',
        params,
      );
      this.tablePaginationProduct.total = count;
      this.tableDataProduct = data;
      this.tableLoadingProduct = false;

      if (!this.drawerShowProduct) {
        this.showDrawer('drawerShowProduct');
      }
    },

    /**
     * 过滤商品查询
     * */
    async filterProduct() {
      const {
        ext1, cusCode, ext15, ext12, ext36,
      } = this.drawerConfig[0].ruleForm;
      const { result } = await this.$http.post(
        '/dms/dms/availableGoods/findHasExistsProductList',
        {
          custCode: cusCode,
          companyCode: ext1,
          responsePosCode: ext15,
          saleContractCode: ext12,
          ...this.drawerConfig[0].ruleForm,
          saleChannel: ext36,
        },
      );
      return result;
    },

    /**
     * 查询负责人
     * @param customerCode {string}
     * @returns {Promise<void>}
     * */
    async searchDocking(customerCode) {
      const { result } = await this.$http.post(
        '/mdm/mdmCustomerMsgExtController/findDockingListByCustomerCode',
        { customerCode },
        {
          headers: {
            ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
          },
        },
      );
      this.userList = result || [];
      this.setFormItemValue(['ext14'], 'data', result);
    },

    /**
     * 查询主合同
     * @param cusCode {string}
     * @returns {Promise<void>}
     * */
    async searchPrimaryContract(cusCode) {
      const { result } = await this.$http.post(
        '/dms/dms/contract/pullDownContractList',
        { cusCode },
      );
      this.setFormItemValue(['ext12'], 'data', result);
    },

    /**
     * 选择列表数据
     * @param data {Array}
     * @returns {boolean}
     */
    selectListData(data) {
      this.selectTableData = data;
    },

    /**
     * 关闭弹窗
     * @param name {string}
     */
    closeDrawer(name) {
      this.selectTableData = [];
      this[name] = false;
    },

    /**
     * 显示列表弹窗 清楚选中的数据
     * @param name {string}
     */
    showDrawer(name) {
      this[name] = true;
      this.selectTableData = [];
    },

    /**
     * 显示表单部分字段
     * @param names {Array<string>} 需要改变的key的集合
     * @param keyName {string} 修改的字段
     * @param data  修改的值
     */
    setFormItemValue(names, keyName, data) {
      this.drawerConfig.forEach((k) => {
        if (k.type === 'form') {
          k.formConfig.items[0].map((i) => {
            if (names.includes(i.key)) {
              if (i[keyName]) {
                i[keyName] = data;
              } else {
                this.$set(i, keyName, data);
              }
              return i;
            }
            return i;
          });
        }
      });
    },

    /**
     * 区分弹窗列表 - 处理对应的数据
     */
    beforeSaveSelect() {
      // 客户
      if (this.drawerShowCustomer === true && !this.selectMessage()) {
        this.saveSelectCustomer();
        this.closeDrawer('drawerShowCustomer');
      }

      if (this.drawerShowProduct === true) {
        // 商品
        this.saveProduct();
        return true;
      }
    },

    /**
     * 弹窗保存验证提示
     */
    selectMessage() {
      if (this.selectTableData.length > 1) {
        this.$message.error('只能选择一条数据');
        return true;
      }
      return false;
    },

    /**
     * 保存客户
     */
    saveSelectCustomer() {
      this.setFormData(this.selectTableData[0].customerCode, 'cusCode');
      this.setFormData(this.selectTableData[0].erpCode, 'erpCode');
      this.setFormData(this.selectTableData[0].customerName, 'cusName');
      this.setFormData(this.selectTableData[0].customerType, 'ext40');
      this.setFormData(null, 'ext14');
      this.setFormData(null, 'ext31');
      this.setFormData(null, 'orgName');
      this.setFormData(null, 'branchOfficeName');
      this.setFormData(null, 'contractType');
      this.setFormData(null, 'ext12');
      this.setFormData(null, 'ext1');
      this.setFormData(0, 'ext21');
      this.setDrawerValue('主合同商品', 'tableData', []);
      this.searchDocking(this.selectTableData[0].customerCode);
      this.searchPrimaryContract(this.selectTableData[0].customerCode);
      this.backData.cusName = this.drawerConfig[0].ruleForm.cusName;
    },

    /**
     * 保存选中的商品
     */
    saveProduct() {
      this.selectTableData.map((i) => {
        i.productCode = i.materialCode;
        i.productName = i.materialName;
        i.spec = i.specification;
        i.saleUnitName = i.baseUnitName;
        i.productPrice = i.productPrice || 0;
        i.discountAfterPrice = 0;
        return i;
      });
      this.selectTableData.forEach((i) => {
        // if (i.isQuotaProduct === '1') {
        //   this.searchQuotaPrice(i, '11');
        // } else {
        //   this.searchPrice(i);
        // }
        this.searchPrice(i);
      });

      this.closeDrawer('drawerShowProduct');
    },

    /**
     * 提交表单数据
     * @param data {Object}
     * @param ext32 {string}
     */
    async requestSave(data = this.drawerConfig[0].ruleForm, ext32) {
      data.productList = this.drawerConfig[1].tableData.map((i) => {
        delete i.id;
        return i;
      });
      data.contractDetailsVo = {
        contractDetails: data.contractDetails,
      };
      data.liabilityForBreachVo = {
        liabilityForBreach: data.liabilityForBreach,
      };
      // eslint-disable-next-line prefer-destructuring
      data.startTime = data.dateRange[0];
      // eslint-disable-next-line prefer-destructuring
      data.endTime = data.dateRange[1];
      data.ext32 = ext32 || 'N';
      console.log(data.contractType);
      console.log((this.userList || []).find((v) => v.positionCode === data.ext14));
      data.ext14 = ((this.userList || []).find((v) => v.positionCode === data.ext14) || {}).userName || '';
      if (data.contractType === '2') {
        this.$message.error('增补合同已取消，请重新选择合同类型');
        return;
      }
      const params = JSON.parse(JSON.stringify(data));
      params.productList.map((i) => {
        const arr = [];
        if (Array.isArray(i.saleArea)) {
          i.saleArea.map((item) => {
            arr.push(item.join('/'));
            return item;
          });
          i.saleArea = arr.join(',');
        }
        return i;
      });
      const url = this.code === 'edit'
        ? '/dms/dms/contract/update'
        : '/dms/dms/contract/save';
      this.loading = true;
      try {
        await this.$http.post(url, params);
        this.$message.success('保存成功');
        this.loading = false;
        this.formShow = false;
        this.getParentTable();
      } catch (e) {
        this.loading = false;
      }
    },

    /**
     * 设置表单数据
     * @param data {}
     * @param dataName {string}
     */
    setFormData(data, dataName) {
      this.$set(this.drawerConfig[0].ruleForm, dataName, data);
    },
    /**
     * 关闭弹窗情况表单值
     *
     */
    setContractFormData(data, dataName) {
      this.searchConfigContract.options.forEach((item) => {
        if (item.valueName === dataName) {
          console.log(item.value);
        }
      });
      // this.$set(this.searchConfigContract.options, dataName, data);
    },
    /**
     * 设置弹窗组件的配置
     * @param componentTitle {String}
     * @param valueName {string}
     * @param data {<void>}
     */
    setDrawerValue(componentTitle, valueName, data) {
      this.drawerConfig.forEach((item) => {
        if (item.title === componentTitle) {
          item[valueName] = data;
        }
      });
    },

    /**
     * 删除商品
     * @param configIndex {number} drawerConfig的第几项配置
     * @param e {Object || string}  选中行的数据
     */
    async deleteProduct(e, configIndex) {
      const { tableData } = this.drawerConfig[configIndex];
      if (e === 'all') {
        this.drawerConfig[configIndex].tableData = [];
        this.setFormData(0, 'ext21');
      } else {
        this.drawerConfig[configIndex].tableData = tableData.filter(
          (i) => i.productCode !== e.productCode,
        );
        if (this.code === 'remove_product_row') {
          // 移除
          e.saleContractCode = this.drawerConfig[0].ruleForm.code;
          await this.$http.post('/dms/dms/contract/updateDetailContractInfo', [
            e,
          ]);
          this.$message.success('移除商品成功');
        }
      }
    },

    /**
     *  商品寻价
     * @returns {Promise<void>}
     */
    async searchPrice(row) {
      const params = {
        priceTypeEnum: 'P002', // 寻价类型
        customerCode: this.drawerConfig[0].ruleForm.erpCode, // erp客户编码
        customerType: this.drawerConfig[0].ruleForm.ext40, // 客户类型
        ext36: this.drawerConfig[0].ruleForm.ext36, // 客户类型
        num: row.num, // 商品数量
        companyCode: this.drawerConfig[0].ruleForm.ext1, // 合同Code
        productCode: row.productCode, // 商品Code
      };

      const { result, code, message } = await this.$http.post(
        '/mdm/mdmPriceSettingExtController/searchAllPrice',
        params,
      );
      if (code === 500) {
        this.$message.error(message);
        return;
      }

      const priceNumberScope = result.itemResultVoList;
      const { tableData } = this.drawerConfig[1];
      priceNumberScope.forEach((i) => {
        i.discountType = i.discountMethod;
        if (i.discountType === '0') {
          // 折扣率
          i.discountRate = i.discount;
        } else {
          i.discountPrice = i.discount;
        }
        tableData.push({ ...row, ...i, quotaPrice: 0 });
        return i;
      });
      this.$nextTick(() => {
        this.setDrawerValue('可购商品', 'tableData', tableData);
        this.mergeTable(1);
      });
    },
    // 配额外价格
    quotaSearchPrice(row) {
      const params = {
        companyCode: row.companyCode,
        productCode: row.productCode,
        customerType: this.drawerConfig[0].ruleForm.ext36,
        quotaPriceType: '12',
      };
      this.$http.post(
        '/mdm/mdmPriceSettingExtController/quotaSearchPrice', params,
      ).then((res) => {
        if (res.success) {
          this.$set(row, 'quotaPrice', res.result ? res.result.price : '');
        }
      });
    },
    /**
     *  配额商品商品寻价
     * @returns {Promise<void>}
     */
    async searchQuotaPrice(row, quotaPriceType) {
      const params = {
        companyCode: this.drawerConfig[0].ruleForm.ext1,
        productCode: row.productCode,
        customerType: this.drawerConfig[0].ruleForm.ext36,
        quotaPriceType,
      };

      const { result, code, message } = await this.$http.post(
        '/mdm/mdmPriceSettingExtController/quotaSearchPrice', params,
      );
      if (code === 500) {
        this.$message.error(message);
        return;
      }
      const { tableData } = this.drawerConfig[1];
      console.log(result);
      result.discountType = result.discountMethod;
      if (result.discountType === '0') { // 折扣率
        result.discountRate = result.discount;
      } else {
        result.discountPrice = result.discount;
      }
      tableData.push({ ...row, ...result, quotaPrice: 0 });
      this.$nextTick(() => {
        this.setDrawerValue('可购商品', 'tableData', tableData);
        this.mergeTable(1);
      });
    },

    /**
     * 保存合并后的数据
     * @param drawConfigIndex {number} 第几项配置
     */
    mergeTable(drawConfigIndex) {
      const obj = {};
      const tableMergeData = [];
      const { tableData } = this.drawerConfig[drawConfigIndex];
      tableData.forEach((i, currentIndex) => {
        if (!obj[i.productCode]) {
          tableMergeData.push(currentIndex);
          obj[i.productCode] = true;
        }
        return i;
      });
      this.drawerConfig[drawConfigIndex].tableMergeData = tableMergeData;
    },

    /**
     * 输入数量
     */
    inputNumber(e) {
      let { tableData } = this.drawerConfig[1];
      console.log(e);
      console.log(tableData);
      let event;
      // tableData.map((i) => {
      //   if (i.productCode === e.productCode) {
      //     i.num = e.num;
      //   }
      //   return i;
      // });
      tableData = tableData.map((i) => {
        if (i.productCode === e.productCode) {
          console.log(i.fromPriceNumber);
          console.log(e.num);
          console.log(i.toPriceNumber);
          if (i.fromPriceNumber <= e.num && e.num <= i.toPriceNumber) {
            console.log(111111111, i);
            // event = i;
            i.num = e.num;
            event = i;
          } else {
            console.log(222222222, i);
            // i.num = 0;
            i.productTotalPrice = 0;
          }
        }
        return i;
      });
      console.log(event);
      this.calculateProductTotalPrice(event);
    },

    /**
     * 计算折扣后的金额
     * @param row {object<void>}
     */
    calculateDiscountAmount(row) {
      if (!row.productPrice) {
        this.$set(row, 'discountAfterPrice', 0);
        this.calculateProductTotalPrice(row);
        return;
      }
      let discountAfterPrice;
      if (
        row.discountType === '0'
        && row.discountRate
        && row.discountRate !== '0'
      ) {
        discountAfterPrice = this.$precise(
          row.productPrice,
          row.discountRate,
          'multiply',
        );
      } else if (
        row.discountType === '1'
        && row.discountPrice
        && row.discountPrice !== '0'
      ) {
        // 当折扣类型=折扣金额时，折后金额=商品单价-折扣
        discountAfterPrice = this.$precise(
          row.productPrice,
          row.discountPrice,
          'subtract',
        );
      } else {
        discountAfterPrice = row.productPrice;
      }
      console.log('discountAfterPrice', discountAfterPrice);
      this.$set(row, 'discountAfterPrice', discountAfterPrice);
      this.calculateProductTotalPrice(row);
    },

    /**
     * 计算商品总价
     * @param row {object}
     */
    calculateProductTotalPrice(row) {
      console.log(row);
      this.$set(
        row,
        'productTotalPrice',
        this.$precise(row.num, row.discountAfterPrice, 'multiply'),
      );
      this.calculateOrderTotalPrice();
    },

    /**
     * 计算订单总价
     */
    calculateOrderTotalPrice() {
      let tableData = [];
      tableData = this.drawerConfig[1].tableData;
      tableData = tableData.filter((v) => {
        if (v.num >= v.fromPriceNumber && v.num <= v.toPriceNumber) {
          return true;
        }
        return false;
      });
      let amount = 0;
      tableData.forEach((i) => {
        console.log(amount);
        amount += Number(i.productTotalPrice || 0) || 0;
      });
      // this.drawerConfig[1].tableData.forEach((i) => {
      //   console.log(amount);
      //   amount += parseInt(i.productTotalPrice,10) || 0;
      // });
      this.setFormData(amount.toFixed(2), 'ext21');
    },

    /**
     * 设置列表头部按钮
     * @param index {number} 第几个按钮
     * @param state {boolean} 状态
     */
    setDrawerTableButton(index, state) {
      this.$set(this.drawerConfig[1].tableButtons[index], 'show', state);
    },

    /**
     * 设置列表头部按钮
     * @param title {string} 列表名称
     * @param names {Array} 需要修改项
     * @param valueName  需要修改的字段
     * @param value  需要修改的字段值
     */
    setDrawerTable(title, names, valueName, value) {
      this.drawerConfig.map((i) => {
        if (i.title === title) {
          i.tableConfig.rows.map((k) => {
            if (names.includes(k.prop)) {
              this.$set(k, valueName, value);
            }
            return k;
          });
        }
        return i;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.drawer-button {
  padding: 20px 20px;
  text-align: right;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #ebeef5;
}

.drawer-table {
  padding: 20px;
  margin-bottom: 100px;
}

.slot-body {
  display: flex;

  div {
    display: flex;
    align-items: center;
    flex-grow: 2;
    span {
      flex-shrink: 0;
      margin-right: 5px;
    }

    input {
      width: 150px;
    }
  }
}
</style>
