import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import _ from 'xe-utils';
import ProductList from '../components/table.vue';

formCreate.component('ProductList', ProductList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'shipment_task_form',
      formParentCode: 'CRM20211217000000005',
    };
  },
  methods: {
    formComplete() {
      const productRespVoList = this.getRule('productRespVoList');
      if (this.formConfig.row && this.formConfig.row.id) {
        request.get('/sfa/sfaShippingTaskController/query', {
          id: this.formConfig.row.id,
        }).then((res) => {
          if (res.success) {
            const { result } = res;
            this.setValue(result);
            productRespVoList.value = result.productRespVoList;
          }
        });
      }
    },

    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'productRespVoList') {
        v.value = [];
      }
      return v;
    },
  },
};
