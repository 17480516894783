var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-table" },
    [
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: { border: "", "show-overflow": "", data: _vm.tableData },
        },
        [
          _c("vxe-table-column", {
            attrs: { field: "productCode", align: "center", title: "产品编码" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "productName", align: "center", title: "产品名称" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "productNum", align: "center", title: "产品数量" },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "productPrice",
              align: "center",
              title: "产品单价",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "productTotalAmount",
              align: "center",
              title: "产品总价",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }