<template>
  <div class="modal-content">
    <div class="org-tree">
      <TablePageTree
        title="组织架构"
        restful="/mdm/baseLazyTreeController/orgLazyTree"
        ref="tableTree"
        @change="treeChange"
      />
    </div>
    <div class="org-table">
      <TablePage
        ref="maintable"
        :columns="columns"
        :data="data"
        :total="total"
        :pageConfig="params"
        :getList="getList"
        :getCheckedList="getCheckedList"
        :loading="loading"
        @pageChange="pageChange"
      >
        <div slot="header" class="tools">
          <p class="tools-title" v-if="formConfig.type === 'chat'">沟通人选</p>
          <el-button v-else type="text" icon="el-icon-circle-plus-outline" @click="setCheckedList"
            >添加</el-button
          >
        </div>
      </TablePage>
    </div>
    <div class="org-table">
      <div v-if="formConfig.type === 'chat'">
        <div class="tools">
          <p class="tools-title">沟通内容</p>
        </div>
        <el-input type="textarea" style="padding: 0 5px" v-model="content" :rows="7"></el-input>
      </div>
      <TablePage
        v-else
        ref="table"
        :columns="columns"
        :data="checkedList"
        :getCheckedList="getCheckedList"
      >
        <div slot="header" class="tools">
          <el-button type="text" icon="el-icon-delete" class="danger" @click="delChecked"
            >删除</el-button
          >
        </div>
      </TablePage>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-button
        type="primary"
        @click="submit()"
        :loading="$store.state.buttonApiIsLoading"
        icon="el-icon-check"
        >提交
      </el-button>
      <el-button type="danger" @click="close" icon="el-icon-close">关闭 </el-button>
    </div>
  </div>
</template>

<script>
import TablePageTree from '../../../../components/table_page_tree';
import TablePage from './table/table.vue';
import request from '../../../../utils/request';

export default {
  name: 'orgManage',
  props: {
    formConfig: Object,
  },
  data() {
    return {
      columns: [
        {
          field: 'userName',
          title: '用户编码',
        },
        {
          field: 'fullName',
          title: '用户名称',
        },
        {
          field: 'positionName',
          title: '职位名称',
        },
      ],
      params: {
        pageSize: 15,
        pageNum: 1,
        code: '',
        positionName: '',
      },
      data: [],
      total: 0,
      checkedList: [], // 已选表格数据
      currentChecked: [], // 当前选中
      loading: false,
      content: '', // 沟通信息
    };
  },
  components: {
    TablePageTree,
    TablePage,
  },
  mounted() {
    // this.intData();
    this.getList();
  },
  computed: {},
  methods: {
    // 组织树选中
    treeChange(code) {
      this.treeCode = code;
      this.params.code = code;
      this.params.positionName = undefined;
      this.params.pageNum = 1;
      this.getList();
    },
    // 获取列表
    getList() {
      this.loading = true;
      request.post('/mdm/mdmUserController/pageList', this.params).then((res) => {
        this.data = res.result.data;
        this.total = res.result.count;

        // 匹配已选与备选数据 用于更改选中状态
        const list = [];
        this.checkedList.map((item) => {
          let i = item;
          this.data.map((d) => {
            if (d.userName === item.userName) i = d;
            return d;
          });
          list.push(i);
          this.$refs.maintable.$refs.xTable.setCheckboxRow(i, true);
          return i;
        });
        this.checkedList = list;
        this.loading = false;
      });
    },
    // 获取当前选中项
    getCheckedList(records) {
      this.currentChecked = records;
    },
    // 设置选中列表
    setCheckedList() {
      const arr = this.checkedList.concat(this.currentChecked);
      const obj1 = {};
      const res1 = [];
      const list = [];
      // 去重
      arr.forEach((item) => {
        if (!obj1[item.userName]) {
          res1.push(item);
          obj1[item.userName] = true;
        }
      });
      // 匹配已选与备选数据 用于更改选中状态
      res1.map((item) => {
        let i = item;
        this.data.map((d) => {
          if (d.userName === item.userName) i = d;
          return d;
        });
        list.push(i);
        return i;
      });
      this.checkedList = list;
    },

    // 删除已选中
    delChecked() {
      this.currentChecked.map((item) => {
        this.checkedList = this.checkedList.filter((cur) => cur.userName !== item.userName);
        this.$refs.maintable.$refs.xTable.setCheckboxRow(item, false);
        this.currentChecked = [];
        return item;
      });
    },
    // 换页
    pageChange(v) {
      this.params.pageNum = v;
      this.getList();
    },
    // // 初始化
    // intData() {
    //   if (this.formConfig && this.formConfig.type === 'copy') {
    //     const params = {
    //       processKey: this.formConfig.row.processKey,
    //     };
    //     request.post('/activiti/taProcessCopyController/findAllByProcessKey', params).then((res) => {
    //       if (res.code === 200) {
    //         res.result.map((item) => {
    //           this.checkedList.push({
    //             fullName: item.userName,
    //             userName: item.userCode,
    //             positionName: item.posName,
    //             positionCode: item.posCode,
    //           });
    //           return item;
    //         });
    //       }
    //     });
    //   }
    // },

    // search() {
    //   this.params.code = this.treeCode;
    //   this.params.positionName = this.posName;
    //   this.getList(this.params);
    // },
    // 保存
    submit() {
      const { type, row } = this.formConfig;
      console.log(this.formConfig);
      console.log(this.checkedList);
      console.log(this.currentChecked);

      if (!type && !this.checkedList.length) {
        this.$message.warning('请选择传阅人员!');
      } else if (type && !this.currentChecked.length) {
        this.$message.warning('请选择沟通人选!');
      } else {
        let url = '/activiti/runtimeController/communicateTo';
        let params = {
          crmProcessInstanceId: this.formConfig.row.crmProcessInstanceId,
          positionCodes: this.checkedList.map((v) => v.positionCode),
        };

        if (type) {
          url = '/activiti/runtimeController/circulate';
          params = {
            content: this.content,
            targetUserNames: this.checkedList,
            taskId: row.taskId,
            // isNeedReply: ''
          };
        }

        request.post(url, params).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.message);

            this.$emit('onClose');

            if (!type) this.$emit('onGetList');
          }
        });
      }
    },
    close() {
      this.$emit('onClose');
    },
  },
};
</script>
<style lang="less" scoped>
.modal-content {
  height: 100%;
  display: flex;

  .org-tree {
    width: 20%;
    height: 100%;
    flex-shrink: 0;
  }

  .org-table {
    width: 40%;
    height: 100%;
    padding-left: 20px;
    flex-shrink: 0;
  }

  .tools {
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding: 0 0 20px;

    .danger {
      color: #f56c6c !important;
    }

    .tools-title {
      line-height: 30px;
      margin: 0;
    }
  }

  /deep/.tree-style {
    height: calc(48vh - 2px);
  }
}
</style>
