<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import ScanList from './components/scanList.vue';

formCreate.component('ScanList', ScanList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'dealer_withdrawal',
      formParentCode: 'CRM20220914000000003',
      rule: [],
      buttons: {
        submit: false,
      },
    };
  },

  methods: {
    setRule(v) {
      const item = v;
      return item;
    },
    formComplete() {
      // 获取详情
      console.log('formConfig', this.formConfig);
      if (this.formConfig.row.id) {
        const list = [];
        const requestUrl = '/cps-mobile/consumercustverifywithdraw/query';
        request.post(requestUrl, { id: this.formConfig.row.id }).then((res) => {
          if (res.success) {
            this.setValue(res.result);
          }
        });
      }
    },
  },
};
</script>
