<template>
  <div>
    <el-scrollbar
      :native="false"
      :noresize="false"
      tag="div"
      :class="{
        'dialog-approve': formConfig.code !== 'view',
        'dialog-view': formConfig.code === 'view',
      }"
      wrap-class="drawer-scrollbar"
    >
      <div class="content">
        <el-tabs v-model="activeName">
          <el-tab-pane label="业务表单" name="second">
            <div class="form-embed" v-if="activeName === 'second'">
              <component
                :is="componentName"
                :noButton="true"
                ref="componentRef"
                :formConfig="subFormConfig"
                :status="status"
                :dmsCode="'view'"
                :id="formId"
                :dmsId="formId"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane label="审批摘要" name="first">
            <Abstract
              v-if="activeName === 'first'"
              :params="{
                crmProcessInstanceId: formConfig.row.crmProcessInstanceId,
              }"
            />
          </el-tab-pane>
          <el-tab-pane label="流程日志" name="third">
            <ProcessLog
              v-if="activeName === 'third'"
              :formConfig="{
                row: { processInstanceId: formConfig.row.processInstanceId },
              }"
            />
          </el-tab-pane>
          <el-tab-pane label="流程图" name="fourth">
            <img
              class="flowImg"
              :src="`/activiti/designController/getProcessPicInfo?processInstanceId=${formConfig.row.processInstanceId}`"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-scrollbar>

    <div class="footer" v-if="formConfig.code !== 'view'">
      <div class="footer-title">请填写审批意见</div>
      <div>
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入内容"
          resize="none"
          v-model="remark"
        ></el-input>
      </div>
      <div class="btn-list">
        <template v-for="(btn, k) in btnList">
          <el-button
            :key="k"
            v-if="btn.btnCode !== 'BTN_001'"
            :loading="buttonApiIsLoading"
            @click="btnOprate(btn.btnCode)"
            >{{ btn.btnName }}</el-button
          >
          <el-upload
            class="upload-box"
            :key="k"
            v-else
            action="/upload/uploadController/uploadOssList"
            multiple
            :file-list="fileList"
            :on-success="uploadFileSuccess"
          >
            <el-button
              :style="{ width: '100%' }"
              :loading="buttonApiIsLoading"
              >{{ btn.btnName }}</el-button
            >
          </el-upload>
        </template>
      </div>
    </div>
    <Modal :modalConfig="subModalConfig" @onClose="close">
      <component
        :is="subFormName"
        ref="subForm"
        @onClose="close"
        @confirmBack="confirmBack"
        :formConfig="subModalFormConfig"
      />
    </Modal>
    <SelectConfig
      ref="selectConfig"
      @onGetSelect="onGetSelect"
      :beforeClose="beforeClose"
    />
  </div>
</template>

<script>
import store from '@/found/store/index';
import QuotaManagement from '@/src/views/dms/views/order/quota_management/adjustment';
import DealerQuotaManagement from '@/src/views/dms/views/order/dealer_quota_management/adjustment';
import factoryReturnTour from '@/found/views/tpm/views/activity/factory_return_tour/form';
import { Loading } from 'element-ui';
import Modal from '../../../../components/modal';
import request from '../../../../utils/request';
import SelectConfig from '../../../../components/select_org_cus_ter/select_config.vue';
import { ProcessLog } from '../../../../modules';
import Abstract from './abstract.vue';
import BackModal from './back_modal.vue';
import CopyHandle from '../copy_handle/copy_handle.vue';
// import Connect from '../org_manage/org_manage.vue';
// import Delegate from './delegate';
import Print from '../../views/dealt/print';

import mixin from './mixin';

export default {
  props: {
    formConfig: Object,
  },
  data() {
    return {
      buttonApiIsLoading: null,
      activeName: 'second',
      componentName: '',
      subFormConfig: {
        code: 'view',
      },
      formId: '',
      status: '',
      remark: '',
      btnList: [],
      fileList: [],
      subModalConfig: {
        title: '标题',
        type: 'Modal',
        visible: false,
      },
      subModalFormConfig: {},
      subFormName: 'Form',
      xfDmsFormConfig: {},
      lastNode: false,
    };
  },
  mixins: [mixin],
  components: {
    Abstract,
    ProcessLog,
    Modal,
    BackModal,
    SelectConfig,
    CopyHandle,
    QuotaManagement,
    DealerQuotaManagement,
    factoryReturnTour,
  },
  created() {
    this.init();
  },
  mounted() {
    this.buttonApiIsLoading = store.state.buttonApiIsLoading;
  },
  methods: {
    // 初始化
    init() {
      const {
        processInstanceId,
        taskId,
        crmProcessInstanceId,
        communicateId,
        businessId,
      } = this.formConfig.row;
      // 获取单据信息
      request
        .post('/activiti/activitiProcessController/getProcessFormParam', {
          processInstanceId,
          taskId,
        })
        .then((res) => {
          const { result } = res;
          this.componentName = result.urlParam;
          this.formId = result.id;
          this.status = result.status;
          this.subFormConfig = {
            ...this.subFormConfig,
            row: result,
            flag: 'audit',
          };
        });
      // 获取权限按钮列表
      if (this.formConfig.code !== 'view') {
        // 判断是否为最后一个节点
        request
          .post('/activiti/activitiController/judgeIsLastNode', {
            taskId,
          })
          .then((res) => {
            const { result } = res;
            this.lastNode = result;
          });
        request
          .post('/activiti/runtimeController/getButtons', {
            processInstanceId,
            processNo: crmProcessInstanceId,
            taskId,
            communicateId,
          })
          .then((res) => {
            if (res.success) {
              console.log(this.lastNode, 'this.lastNode');
              if (this.lastNode) {
                this.btnList = ((res.result || []).filter((v) => v.btnCode !== 'BTN_006') || []);
              } else {
                this.btnList = res.result;
              }
            }
          });
      }
    },

    // 按钮操作
    btnOprate(code) {
      const { crmProcessInstanceId, taskId } = this.formConfig.row;

      let url = null;
      let params = null;

      switch (code) {
        case 'BTN_004':
          // 通过
          url = '/activiti/activitiController/completeTasks';
          params = {
            crmProcessInstanceId,
            pass: true,
            taskId,
            remarks: this.remark,
          };
          break;
        case 'BTN_005':
          // 回退
          if (!this.remark) {
            this.$message.error('请填写回退原因');
          } else {
            url = '/activiti/activitiRebackController/rejectToLatestHisTask';
            params = {
              taskId,
              remark: this.remark,
            };
          }
          break;
        case 'BTN_006':
          // 驳回
          if (!this.remark) {
            this.$message.error('请填写驳回原因');
          } else {
            url = '/activiti/activitiController/completeTasks';
            params = {
              crmProcessInstanceId,
              pass: false,
              taskId,
              remarks: this.remark,
            };
          }
          break;
        case 'BTN_024':
          // 报车
          this.$confirm('是否确认报车？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              request
                .postform('/dms/dms/order/carReport', [this.formId])
                .then((res) => {
                  if (res.success) {
                    this.$message.success('报车成功！');
                  }
                });
            })
            .catch(() => {});
          break;
        case 'BTN_020':
          // 发起沟通
          this.subModalFormConfig = {
            type: 'chat',
          };
          this.subFormName = 'CopyHandle';
          this.subModalConfig = {
            title: '沟通',
            type: 'Modal',
            visible: true,
          };

          break;
        case 'BTN_025': // 抄送
          break;
        case 'BTN_012': // 加签
          break;
        case 'BTN_031': // 打印
          console.log(this.formConfig.row);
          request
            .post('/mdm/mdmdictdata/list', {
              dictTypeCode: 'print_process_key',
            })
            .then((res) => {
              if (res.success) {
                for (const item of res.result) {
                  if (
                    item.process_key.indexOf(this.formConfig.row.processKey)
                    >= 0
                  ) {
                    new Print({
                      id: this.formConfig.row.businessId,
                      logId: this.formConfig.row.processInstanceId,
                      type: item.dictCode,
                    }).print();
                    break;
                  }
                }
              }
            });
          break;
        case 'BTN_010':
          // 转办
          // this.subFormName = 'Delegate';

          // this.subModalConfig.title = '转办';
          // this.subModalConfig.visible = true;
          // params = {
          //   functionCode: 'process_instance_delegate_table',
          //   noReset: true,
          // };
          // this.$refs.selectConfig.openSelectModal(params);
          break;
        // case 'BTN_003':
        // url = '/activiti/taTaskController/readCopyTask';
        // params = {
        //   copyTaskId,
        // };
        // break;
        // case 'BTN_005':
        //   this.subFormName = 'BackModal';
        //   this.subModalFormConfig = {
        //     taskId,
        //   };
        //   this.subModalConfig.title = '回退';
        //   this.subModalConfig.visible = true;
        //   break;
        // case 'BTN_014':
        //   url = '/activiti/taTaskController/reStartProcess';
        //   params = {
        //     taskId,
        //   };
        //   break;
        // case 'BTN_018':
        //   // fn = this.send;
        //   console.log(code);
        //   break;
        // case 'BTN_022':
        //   url = '/activiti/taTaskController/taskRejectEnd';
        //   params = {
        //     taskId,
        //     optBtnCode: code,
        //     fileList: this.fileList,
        //     remark: this.remark,
        //   };
        //   break;
        default:
          console.log(code);
          break;
      }

      if (url) this.callBackFn(url, params, code);
    },
    // 确定选择关闭弹框前置函数
    beforeClose(val) {
      const state = val.length > 0;
      if (!state) {
        this.$message.error('请选择数据');
      }

      return state;
    },
    // 选择数据回调
    onGetSelect(val) {
      const params = {
        delegatePositionCode: val[0].positionCode, // '委派人员岗位编码',
        taskId: this.formConfig.row.taskId, // 任务ID
      };
      request
        .post('/activiti/taactbaseprocess/delegate', params)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
          }
        });
    },
    // 上传成功
    uploadFileSuccess(res) {
      if (res.code === 200 && res.success) {
        this.fileList = [
          ...this.fileList,
          ...res.result.map((a) => ({
            fileAddress: a.url,
            objectName: a.fileName,
            name: a.fileName,
          })),
        ];
      }
    },
    async beforeCallBack(code) {
      let state = true;
      if (this.$refs.componentRef) {
        if (this.$refs.componentRef.beforeSubmit) {
          state = await this.$refs.componentRef.beforeSubmit(code);
        }
      }

      return state;
    },
    async callBackFn(url, params, code) {
      const loadingInstance = Loading.service({
        fullscreen: true,
        text: '加载中...',
        background: 'rgba(0, 0, 0, 0.8)',
      });
      const state = await this.beforeCallBack(code);
      if (!state) return false;
      request.post(url, params).then((res) => {
        if (res.success) {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          this.$message.success(res.message);
          this.$emit('onClose');
          this.$emit('onGetList');
        } else {
          this.$nextTick(() => {
            loadingInstance.close();
          });
        }
      }).catch((err) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
      });
    },
    // 确认成功回退
    confirmBack() {
      this.$emit('onClose');
      this.$emit('onGetList');
    },
    // 关闭弹框
    close() {
      this.subModalConfig = {
        title: '标题',
        type: 'Modal',
        visible: false,
      };
      this.subFormConfig = {};
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-approve {
  height: calc(100vh - 233px);
}

.dialog-view {
  height: calc(100vh - 56px);
}

.content {
  padding: 0 20px;
  box-sizing: border-box;
}

/deep/.drawer-scrollbar {
  overflow-x: hidden;
}

.upload-box {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  margin-right: 10px;
}

.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 20px;
  z-index: 99;
  background-color: white;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);

  .footer-title {
    font-size: 15px;
    color: #333333;
    line-height: 16px;
    margin-bottom: 10px;
  }

  .btn-list {
    margin-top: 10px;
  }
}
</style>
