var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-content" }, [
    _c(
      "div",
      { staticClass: "org-tree" },
      [
        _c("TablePageTree", {
          ref: "tableTree",
          attrs: {
            title: "组织架构",
            restful: "/mdm/baseLazyTreeController/orgLazyTree",
          },
          on: { change: _vm.treeChange },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "org-table" },
      [
        _c(
          "TablePage",
          {
            ref: "maintable",
            attrs: {
              columns: _vm.columns,
              data: _vm.data,
              total: _vm.total,
              pageConfig: _vm.params,
              getList: _vm.getList,
              getCheckedList: _vm.getCheckedList,
              loading: _vm.loading,
            },
            on: { pageChange: _vm.pageChange },
          },
          [
            _c(
              "div",
              {
                staticClass: "tools",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _vm.formConfig.type === "chat"
                  ? _c("p", { staticClass: "tools-title" }, [
                      _vm._v("沟通人选"),
                    ])
                  : _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          icon: "el-icon-circle-plus-outline",
                        },
                        on: { click: _vm.setCheckedList },
                      },
                      [_vm._v("添加")]
                    ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "org-table" },
      [
        _vm.formConfig.type === "chat"
          ? _c(
              "div",
              [
                _vm._m(0),
                _c("el-input", {
                  staticStyle: { padding: "0 5px" },
                  attrs: { type: "textarea", rows: 7 },
                  model: {
                    value: _vm.content,
                    callback: function ($$v) {
                      _vm.content = $$v
                    },
                    expression: "content",
                  },
                }),
              ],
              1
            )
          : _c(
              "TablePage",
              {
                ref: "table",
                attrs: {
                  columns: _vm.columns,
                  data: _vm.checkedList,
                  getCheckedList: _vm.getCheckedList,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "tools",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "danger",
                        attrs: { type: "text", icon: "el-icon-delete" },
                        on: { click: _vm.delChecked },
                      },
                      [_vm._v("删除")]
                    ),
                  ],
                  1
                ),
              ]
            ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "dialog-footer",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "el-button",
          {
            attrs: {
              type: "primary",
              loading: _vm.$store.state.buttonApiIsLoading,
              icon: "el-icon-check",
            },
            on: {
              click: function ($event) {
                return _vm.submit()
              },
            },
          },
          [_vm._v("提交 ")]
        ),
        _c(
          "el-button",
          {
            attrs: { type: "danger", icon: "el-icon-close" },
            on: { click: _vm.close },
          },
          [_vm._v("关闭 ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tools" }, [
      _c("p", { staticClass: "tools-title" }, [_vm._v("沟通内容")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }