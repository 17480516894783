<template>
  <el-form label-width="150px">
    <el-row>
      <el-col :span="12">
        <el-form-item label="申请人">
          <el-input v-model="realName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="申请人职位名称">
          <el-input v-model="posName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="申请人所属组织">
          <el-input v-model="orgName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="申请日期">
          <el-input v-model="applicationDate" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="申请类型">
          <el-input v-model="addWorkTypeName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="开始时间">
          <el-input v-model="beginTime" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="结束时间">
          <el-input v-model="endTime" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="申请时长">
          <el-input v-model="addWorkDuration" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="审批状态">
          <el-input v-model="bpmStatusName" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="申请事由">
          <el-input v-model="addWorkReason" type="textarea" resize='none' :rows="5"  readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <!-- <el-row>
      <el-col :span="24">
        <el-form-item label="申请日期">
          <vxe-table :data="timeInfoListJson">
            <vxe-table-column field="timeStr" title="日期"></vxe-table-column>
            <vxe-table-column field="timeTypeName" title="类型"></vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-col>
    </el-row> -->
    <!-- <el-row>
      <el-col :span="24">
        <el-form-item label="销假明细">
          <vxe-table :data="leaveCancelRespVoList">
            <vxe-table-column title="日期明细" type="expand">
              <template v-slot:content="{row}">
                <vxe-table :data="row.timeInfoList">
                  <vxe-table-column title="日期" field="timeStr"></vxe-table-column>
                  <vxe-table-column title="日期类型" field="timeTypeName"></vxe-table-column>
                </vxe-table>
              </template>
            </vxe-table-column>
            <vxe-table-column title="销假开始时间" field="beginTime"></vxe-table-column>
            <vxe-table-column title="销假结束时间" field="endTime"></vxe-table-column>
            <vxe-table-column title="销假明细" field="timeDetail"></vxe-table-column>
            <vxe-table-column title="销假天数（天）" field="cancelDays"></vxe-table-column>
            <vxe-table-column title="审批状态" field="bpmStatusName"></vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-col>
    </el-row> -->
    <el-row>
      <el-col :span="24" class="img-url">
        <el-form-item label="附件">
          <el-image v-for="(item,index) in attachmentListJson" :preview-src-list="[item.picUrl+item.picPath]" :src="item.picUrl+item.picPath" :key="index"></el-image>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
const enumTimeType = {
  1: '全天',
  2: '上午',
  3: '下午',
};
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {
          realName: '', // 申请人
          posName: '', // 申请人职位名称
          orgName: '', // 申请人所属组织
          applicationDate: '', // 申请日期
          addWorkTypeName: '', // 申请类型
          beginTime: '', // 开始时间
          endTime: '', // 结束时间
          addWorkDuration: '', // 申请时长
          bpmStatusName: '', // 审批状态
          addWorkReason: '', // 申请事由
          leaveCancelRespVoList: [], // 销假明细
          attachmentListJson: [], // 附件
        };
      },
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.realName = val.realName;
          this.posName = val.posName;
          this.orgName = val.orgName;
          this.applicationDate = val.applicationDate;
          this.addWorkTypeName = val.addWorkTypeName;
          if (val.addWorkType === '002') {
            const beginTime = val.beginTime.split(' ');
            const [date, time] = beginTime;
            this.beginTime = date;
            this.endTime = date;
            this.addWorkDuration = `${val.addWorkDuration}天`;
          } else if (val.addWorkType === '001') {
            this.beginTime = val.beginTime;
            this.endTime = val.endTime;
            this.addWorkDuration = `${val.addWorkDuration}小时`;
          } else {
            this.beginTime = '';
            this.endTime = '';
            this.addWorkDuration = '';
          }
          // this.beginTime = val.addWorkType === '002' ?val.beginTime.split(" ")[0]:val.addWorkType === '001' ? val.beginTime : "";
          // this.endTime = val.addWorkType === '002' ?val.beginTime.split(" ")[0] :val.addWorkType === '001' ? val.endTime:"";
          // this.addWorkDuration = val.addWorkType === '001' ? val.addWorkDuration + '小时': val.addWorkType === '002' ? val.addWorkDuration + '天' : '';
          this.bpmStatusName = val.bpmStatusName;
          this.addWorkReason = val.addWorkReason;
          this.attachmentListJson = val.attachmentListJson ? JSON.parse(val.attachmentListJson) : [];
          this.timeInfoListJson = val.timeInfoListJson ? JSON.parse(val.timeInfoListJson).map((item) => ({ ...item, timeTypeName: enumTimeType[item.timeType] })) : [];
          /** @todo 这是一个双循环 */
          this.leaveCancelRespVoList = val.leaveCancelRespVoList ? val.leaveCancelRespVoList.map((item) => {
            const row = { ...item };
            if (row.timeInfoList && Array.isArray(row.timeInfoList)) {
              // row.timeInfoList = row.timeInfoList.map((mitem) => ({ ...mitem, timeTypeName: enumTimeType[mitem.timeType] }));
              row.timeDetail = '';
              row.timeInfoList.map((mitem) => {
                row.timeDetail += `${mitem.timeStr}：${enumTimeType[mitem.timeType]}\n`;
                return row.timeDetail;
              });
            }
            return row;
          }) : [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      realName: '', // 申请人
      posName: '', // 申请人职位名称
      orgName: '', // 申请人所属组织
      applicationDate: '', // 申请日期
      addWorkTypeName: '', // 申请类型
      beginTime: '', // 开始时间
      endTime: '', // 结束时间
      addWorkDuration: '', // 申请时长
      bpmStatusName: '', // 审批状态
      addWorkReason: '', // 申请事由
      attachmentListJson: [], // 附件
      leaveCancelRespVoList: [], // 销假明细
      timeInfoListJson: [], // 日期
    };
  },
};
</script>

<style lang="less" scoped>
  /deep/.el-image__inner ,
  /deep/ .el-image__error{
    width: 120px;
    height: 120px;
    margin-right: 6px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    outline: 0;
    object-fit: contain;
  }
  /deep/ .img-url .el-form-item__content > div{
    width: auto;
  }
</style>
