var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-scrollbar",
        {
          class: {
            "dialog-approve": _vm.formConfig.code !== "view",
            "dialog-view": _vm.formConfig.code === "view",
          },
          attrs: {
            native: false,
            noresize: false,
            tag: "div",
            "wrap-class": "drawer-scrollbar",
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "业务表单", name: "second" } },
                    [
                      _vm.activeName === "second"
                        ? _c(
                            "div",
                            { staticClass: "form-embed" },
                            [
                              _c(_vm.componentName, {
                                ref: "componentRef",
                                tag: "component",
                                attrs: {
                                  noButton: true,
                                  formConfig: _vm.subFormConfig,
                                  status: _vm.status,
                                  dmsCode: "view",
                                  id: _vm.formId,
                                  dmsId: _vm.formId,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "审批摘要", name: "first" } },
                    [
                      _vm.activeName === "first"
                        ? _c("Abstract", {
                            attrs: {
                              params: {
                                crmProcessInstanceId:
                                  _vm.formConfig.row.crmProcessInstanceId,
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "流程日志", name: "third" } },
                    [
                      _vm.activeName === "third"
                        ? _c("ProcessLog", {
                            attrs: {
                              formConfig: {
                                row: {
                                  processInstanceId:
                                    _vm.formConfig.row.processInstanceId,
                                },
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "流程图", name: "fourth" } },
                    [
                      _c("img", {
                        staticClass: "flowImg",
                        attrs: {
                          src:
                            "/activiti/designController/getProcessPicInfo?processInstanceId=" +
                            _vm.formConfig.row.processInstanceId,
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.formConfig.code !== "view"
        ? _c("div", { staticClass: "footer" }, [
            _c("div", { staticClass: "footer-title" }, [
              _vm._v("请填写审批意见"),
            ]),
            _c(
              "div",
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    rows: 3,
                    placeholder: "请输入内容",
                    resize: "none",
                  },
                  model: {
                    value: _vm.remark,
                    callback: function ($$v) {
                      _vm.remark = $$v
                    },
                    expression: "remark",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "btn-list" },
              [
                _vm._l(_vm.btnList, function (btn, k) {
                  return [
                    btn.btnCode !== "BTN_001"
                      ? _c(
                          "el-button",
                          {
                            key: k,
                            attrs: { loading: _vm.buttonApiIsLoading },
                            on: {
                              click: function ($event) {
                                return _vm.btnOprate(btn.btnCode)
                              },
                            },
                          },
                          [_vm._v(_vm._s(btn.btnName))]
                        )
                      : _c(
                          "el-upload",
                          {
                            key: k,
                            staticClass: "upload-box",
                            attrs: {
                              action: "/upload/uploadController/uploadOssList",
                              multiple: "",
                              "file-list": _vm.fileList,
                              "on-success": _vm.uploadFileSuccess,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                style: { width: "100%" },
                                attrs: { loading: _vm.buttonApiIsLoading },
                              },
                              [_vm._v(_vm._s(btn.btnName))]
                            ),
                          ],
                          1
                        ),
                  ]
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      _c(
        "Modal",
        {
          attrs: { modalConfig: _vm.subModalConfig },
          on: { onClose: _vm.close },
        },
        [
          _c(_vm.subFormName, {
            ref: "subForm",
            tag: "component",
            attrs: { formConfig: _vm.subModalFormConfig },
            on: { onClose: _vm.close, confirmBack: _vm.confirmBack },
          }),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        attrs: { beforeClose: _vm.beforeClose },
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }