import request from '../../../../../../utils/request';
import Form, { formCreate } from '../../../../../../components/form';
import { Budget } from '../../components';

formCreate.component('ActivityBudget', Budget);

export default {
  extends: Form,
  props: {
    id: String,
  },
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formParentCode: 'CRM20210308000001381',
      formFunctionCode: 'tpm_activity_manage_form',
      buttonText: {
        submit: '保存',
      },
      buttons: {
        submitAudit: this.formConfig.code !== 'view',
      },
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'fineName') {
        v.restful = '/tpm/tpmCostTypeFineController/list';
        v.optionsKey = { label: 'fineName', value: 'fineName' };
      } else if (v.field === 'orgCodeList') {
        // // v.restful = '/tpm/tpmLiqueurActController/findOrgMapByCodeAndIsCross';
        // // v.optionsKey = { label: 'orgName', value: 'orgCode' };
        // v.restfulParams = { enableStatus: '009' };
        // v.restfulParamsGetValue = { fineCode: 'fineCode' };
        v.props = {
          ...v.props,
          restful: '/tpm/tpmLiqueurActController/findOrgMapByCodeAndIsCross',
          // restfulParams: { enableStatus: '009', isQueryOrgTree: 'Y' },
          // restfulGetValue: () => ({ fineCode: this.getFieldValue('fineCode') }),
          refresh: true,
          props: {
            value: 'code', // 唯一标识
            key: 'code',
            label: 'name', // 标签显示
            children: 'children', // 子级
          },
        };
        v.value = '';
      } else if (v.field === 'customerCodeList') {
        v.restful = '/tpm/tpmLiqueurActController/findCusMapByOrgCode';
        v.optionsKey = { label: 'customerName', value: 'customerCode' };
        // v.restfulParamsGetValue = { orgCodeList: 'orgCodeList' };
        v.props = {
          ...v.props,
          collapseTags: true,
        };
        v.refresh = true;
      } else if (v.field === 'budgetControlVos') {
        v.props = {
          ...v.props,
          type: 'form',
          getField: (field) => this.getFieldValue(field),
        };
      } else if (v.field === 'beginDate' || v.field === 'endDate') {
        v.props = {
          ...v.props,
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          relation: {
            beginField: 'beginDate',
            endField: 'endDate',
            gtNow: true,
          },
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      const beginDate = this.getRule('beginDate');
      beginDate.on.change = (e) => {
        this.setValue({
          budgetControlVos: [],
        });
      };
      const endDate = this.getRule('endDate');
      endDate.on.change = (e) => {
        this.setValue({
          budgetControlVos: [],
        });
      };

      const fineName = this.getRule('fineName');
      const orgCodeList = this.getRule('orgCodeList');
      const customerCodeList = this.getRule('customerCodeList');

      fineName.on.change = (e) => {
        const item = fineName.options.find((v) => v.fineName === e);
        const fineCode = item ? item.fineCode : '';

        this.disabled(!e, 'orgCodeList');
        this.disabled(true, 'customerCodeList');

        this.setValue({
          fineCode,
          budgetControlVos: [],
          orgCodeList: '',
          customerCodeList: [],
        });

        orgCodeList.props.restfulParams = { enableStatus: '009', isQueryOrgTree: 'Y', fineCode: item.fineCode };
      };

      orgCodeList.on.change = (e) => {
        this.disabled(!e, 'customerCodeList');
        this.setValue({
          customerCodeList: [],
          budgetControlVos: [],
        });

        customerCodeList.restfulParams = { orgCodeList: e.split(',') };
      };

      customerCodeList.on.change = (e) => {
        this.setValue({
          budgetControlVos: [],
        });
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view' || this.id) {
        request
          .get('/tpm/tpmLiqueurActController/query', {
            id: this.formConfig.row ? this.formConfig.row.id : this.id,
          })
          .then((res) => {
            if (res.success) {
              const data = res.result;

              // 预算
              data.budgetControlVos = data.editBudgetVos
                ? data.editBudgetVos.map((item) => {
                  const v = item;
                  v.editId = v.id;
                  return v;
                })
                : [];

              const orgList = data.orgCode ? JSON.parse(data.orgCode) : [];

              // 组织
              data.orgCodeList = orgList.join(',');
              // 客户
              data.customerCodeList = data.customerCode ? JSON.parse(data.customerCode) : [];
              data.fileVos = data.fileVos ? data.fileVos.map((v) => ({
                url: v.fileAddress,
                fileName: v.objectName,
              })) : [];

              customerCodeList.restfulParams = { orgCodeList: orgList };
              orgCodeList.props.restfulParams = { enableStatus: '009', isQueryOrgTree: 'Y', fineCode: data.fineCode };

              this.setValue(data);
            }
          });
      }
    },
    // 提交
    submit(e) {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/tpm/tpmLiqueurActController/save';
        const params = { ...formData };

        params.saveType = e.toString();
        params.fileVos = params.fileVos ? params.fileVos.map((v) => ({
          ...v,
          fileAddress: v.url,
          objectName: v.fileName,
        })) : [];

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmLiqueurActController/update';
          params.id = this.formConfig.row.id;

          params.saveType = (e + 2).toString();
        }

        params.orgCodeList = params.orgCodeList.split(',').filter((v) => v);

        if (e === 2) {
          this.$emit('submit', {
            row: params,
            submitUrl: url,
          });
        } else {
          request.post(url, params).then((res) => {
            if (res.success) {
              this.$message.success('操作成功');
              this.$emit('onClose');
              this.$emit('onGetList');
            }
          });
        }
      }
    },
  },
};
