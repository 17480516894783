<template>
  <div class="abstract">
    <div class="title">摘要</div>
    <div class="list describe">
      <div class="item">
        <div class="label">流程名称</div>
        <div class="val">{{detail.processName}}</div>
      </div>
      <div class="item">
        <div class="label">流程编号</div>
        <div class="val">{{detail.processNo}}</div>
      </div>
      <div class="item">
        <div class="label">发起人</div>
        <div class="val">{{detail.applierName}}</div>
      </div>
      <div class="item">
        <div class="label">发起人职位</div>
        <div class="val">{{detail.applierPositionName}}</div>
      </div>
      <div class="item">
        <div class="label">主题</div>
        <div class="val">{{detail.title}}</div>
      </div>
      <div class="item">
        <div class="label">发起时间</div>
        <div class="val">{{detail.createTime}}</div>
      </div>
      <div class="item">
        <div class="label">详情</div>
        <div class="val">{{detail.remarks}}</div>
      </div>
    </div>
    <div class="title" v-if="detail.taActTargetRespVos.length > 0">业务关键指标</div>
    <div class="list" v-if="detail.taActTargetRespVos.length > 0">
      <vxe-table :data="detail.keyTargetList" show-overflow>
        <vxe-table-column v-for="(item, k) in detail.taActTargetRespVos" :key="k" v-bind="item"></vxe-table-column>
      </vxe-table>
    </div>
    <div class="title">附件</div>
    <div class="list">
      <vxe-table :data="detail.processFiles">
        <vxe-table-column field="objectName" title="附件名称"></vxe-table-column>
        <vxe-table-column field="createName" title="上传者"></vxe-table-column>
        <vxe-table-column field="createDate" title="上传时间"></vxe-table-column>
        <!-- <vxe-table-column field title="文件大小"></vxe-table-column> -->
        <vxe-table-column title="操作" width="100" align="center">
          <template #default="{ row }">
            <a :href="`/upload/downloadController/download?objectName=${row.fileAddress}`" :download="row.objectName">下载</a>
            <!-- <el-link type="success" :href="`/upload/downloadController/download?objectName=${row.objectName}`">下载</el-link> -->
            <!-- <el-link class="text-red">删除</el-link> -->
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>
  </div>
</template>

<script>
import request from '../../../../utils/request';

export default {
  props: {
    params: Object,
  },
  data() {
    return {
      detail: {
        taActTargetRespVos: [],
        keyTargetList: [],
        processFiles: [],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      request
        .get('/activiti/runtimeController/getProcessSummary', this.params)
        .then((res) => {
          if (res.success) {
            const data = res.result;
            const columns = data.taActTargetRespVos.map((v) => {
              const {
                field, title, visible, width,
              } = v;
              return {
                field,
                title,
                visible: visible === 'Y',
                width,
                minWidth: 130,
              };
            });

            data.taActTargetRespVos = columns.filter((v) => v.visible);
            this.detail = res.result;
          }
        });
    },
    // 下载
    downLoad(row) {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = row.fileAddress;
      link.setAttribute('download', row.objectName); // 文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // 下载完成移除元素
      window.URL.revokeObjectURL(link.href); // 释放掉blob对象
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  border-left: 4px solid #409eff;
  margin-bottom: 10px;
  padding-left: 10px;
  font-size: 15px;
}

.list {
  padding: 0 0 20px 14px;
}

.describe {
  line-height: 40px;
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 50%;
    flex-shrink: 0;
    display: flex;

    .label {
      width: 100px;
      flex-shrink: 0;
      color: #333;
      font-weight: bold;
    }

    .val {
      flex: 1;
      flex-shrink: 0;
    }
  }
}

.text-red {
  color: #f56c6c !important;
}
</style>
